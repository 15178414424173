import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useStateContext } from "../context/ContextProvider";
import DOMPurify from 'dompurify';
import { useParams } from 'react-router-dom';
import '../styles/MenuPage.css';

const MenuPage = () => {
    const [content, setContent] = useState('');
    const [languages, setLanguages] = useState([]);
    const { lang } = useStateContext();
    const { id } = useParams();

    useEffect(() => {
        const fetchLanguages = async () => {
          const response = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/api/Language/get-all-languages`);
          setLanguages(response.data.data);
        };
    
        fetchLanguages();
      }, []);

      useEffect(() => {
        const fetchContent = async () => {
          const response = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/api/Menu/get-menu/${id}`);
          if (response.data.isSuccessful) {
            const languageId = languages.find(language => language.languageCode === lang)?.languageId;
            const contentData = response.data.data.menuTranslates.find(translate => translate.languageId === languageId);
            setContent(contentData ? DOMPurify.sanitize(contentData.menuContent) : '');
          }
        };
    
        if (languages.length > 0) {
          fetchContent();
        }
      }, [id, lang, languages]);

  return (
    <div className="menu-content" dangerouslySetInnerHTML={{ __html: content }} />
  );
};

export default MenuPage;