import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useStateContext } from "../context/ContextProvider";
import CryptoJS from "crypto-js";
import "../styles/CreditPayment.css";

const CreditPayment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const encodedFinCode = queryParams.get("finCode");

  const [creditInfo, setCreditInfo] = useState(null);
  const [firstItem, setFirstItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [visibleItems, setVisibleItems] = useState({});

  let decodedFinCode = decodeURIComponent(encodedFinCode);
  const postFincode = decodedFinCode;
  //decodedFinCode = decodedFinCode.replace(/%2B/g, '+').replace(/%2F/g, '/');
  decodedFinCode = decodedFinCode.replace(/\+/g, "%2B").replace(/\//g, "%2F");

  useEffect(() => {
    setIsLoading(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/CreditInfo/get-credit-contract?contract_key=${decodedFinCode}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const sorted = data.data.sort((a, b) => {
          const dateA = new Date(a.mustPayDate);
          const dateB = new Date(b.mustPayDate);
          return dateA - dateB;
        });
        setCreditInfo(sorted);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        if (error.message.includes("400")) {
          alert("Sehv kod daxil etdiniz. Zehmet olmasa yeniden cehd edin");
          navigate("/");
        }
      });
  }, [encodedFinCode]);

  useEffect(() => {
    if (creditInfo !== null && creditInfo.length > 0) {
      console.log("creditInfo", creditInfo);
      setFirstItem(creditInfo[0].fullName);
    }
  }, [creditInfo]);

  const processPayment = (fincode, amount, contract_id, name) => {
    const data = {
      fincode: fincode,
      amount: amount,
      contract_id: contract_id,
      name: name,
      time: "string",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/CreditInfo/payment-credit-request`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.text())
      .then((form) => {
        const wrapper = document.createElement("div");
        //form = form.replace(/<script>.*<\/script>/, ''); // remove script from form
        wrapper.innerHTML = form;
        document.body.appendChild(wrapper);
        console.log("Success:", form);
        document.forms["yavuz"].submit();
      })
      .catch((error) => console.error("Error:", error));
  };

  console.log(creditInfo);

  return isLoading ? (
    <div>Yükleniyor...</div>
  ) : (
    <div
      className="container credit-info mx-auto mt-3"
      style={{ minHeight: "100vh" }}
    >
      <div className="row">
        {creditInfo.length > 0 ? (
          creditInfo.map((item, index) => (
            <div key={index} className="col-12">
              <div className="credit-item mx-auto">
                <table>
                  <tbody>
                    <th>Müqavilə nömrəsi {item.creditId}</th>
                    <th></th>
                    <tr>
                      <td>
                        <span className="fw-bold">Müşteri adı:</span>{" "}
                        {item.fullName}
                      </td>
                      <td>
                        <label
                          className="fw-bold"
                          for={`payment-amount-${index}`}
                        >
                          Ödəniş məbləği:
                        </label>
                        <div className="input-currency-wrapper">
                          <input
                            defaultValue={item.residialPayment}
                            id={`payment-amount-${index}`}
                            type="number"
                            min="0"
                          />
                          <span className="currency-symbol">₼</span>{" "}
                          {/* Replace with your currency symbol */}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div key={index}>
                  <div className={`details-wrapper `}>
                    <div className="item-details bg-white px-2 mt-2">
                      <div>
                        <strong>Müqavilə nömrəsi:</strong> {item.creditId}
                      </div>
                      <div>
                        <strong>Qalıq Məbləğ:</strong> {item.residialAmount}
                      </div>
                      <div>
                        <strong>Qalıq Ödeme:</strong> {item.residialPayment}
                      </div>
                      <div>
                        <strong>Məcburi Ödəniş:</strong> {item.mustPayAmount}
                      </div>
                      <div>
                        <strong>Müqavilə Tarixi:</strong> {item.dateOfGrant}
                      </div>
                      <div>
                        <strong>Son Ödeme Tarixi:</strong> {item.mustPayDate}
                      </div>
                    </div>
                  </div>
                  <div className="button-wrapper mt-3">
                    <button
                      onClick={() =>
                        processPayment(
                          postFincode,
                          document.getElementById(`payment-amount-${index}`)
                            .value,
                          item.creditId,
                          item.fullName
                        )
                      }
                    >
                      Ödəniş et
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="my-3">
            <h3 className="text-center fw-semibold">Sonuç Bulunamadı!</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreditPayment;
