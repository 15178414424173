import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import Select from "react-select";
import InputMask from "react-input-mask";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import axiosInstance from "../axiosInstance";
import CryptoJS from "crypto-js";
import { Link } from "react-router-dom";
import {
  IoCartOutline,
  IoHeartOutline,
  IoCallOutline,
  IoSearch,
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoYoutube,
  IoLogoWhatsapp,
  IoLogoTiktok,
  IoLogoLinkedin,
  IoLogoTwitter,
  IoNavigateCircle,
  IoApps,
  IoAppsOutline,
  IoClose,
  IoCaretDownOutline,
  IoCaretForward,
  IoMenu,
  IoShareSocialSharp,
  IoInformationCircleOutline,
  IoWalletOutline,
  IoSwapHorizontal,
  IoPersonOutline,
  IoCashOutline,
  IoChatbubbleEllipsesOutline,
  IoHome,
  IoCallSharp,
  IoCall,
  IoPersonCircleSharp,
} from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../context/ContextProvider";
import { useNavigate } from "react-router-dom";
import MonthlyPayment from "./MonthlyPayment";
import "../styles/Header.css";

const CatNavSearch = (categoryId) => {
  window.location.href = `/az/searchResult?categoryId=${categoryId}`;
};

const CategoryItem = ({ category, isFirstLevel }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    if (!category.childCategories.length) {
      CatNavSearch(category.categoryId);
    } else {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <li
      className="p-3"
      onClick={() => {
        if (!category.childCategories.length) {
          CatNavSearch(category.categoryId);
        }
      }}
    >
      <div
        className="d-flex justify-content-between align-items-center c_pointer"
        onClick={toggleExpansion}
      >
        <div className="d-flex align-items-center">
          {category.categoryImage && (
            <img
              src={category.categoryImage}
              alt={category.categoryTitle}
              className="me-2"
              height={30}
            />
          )}
          <span>{category.categoryTitle}</span>
        </div>
        {category.childCategories.length > 0 &&
          (isExpanded ? <IoCaretDownOutline /> : <IoCaretForward />)}
      </div>
      {isExpanded && category.childCategories.length > 0 && (
        <ul className="list-unstyled ms-3 ps-0">
          {category.childCategories.map((childCategory) => (
            <CategoryItem
              key={childCategory.categoryId}
              category={childCategory}
              isFirstLevel={false}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

const CategoryList = ({ categories }) => {
  return (
    <ul className="mobile_cat_lists list-unstyled ps-0">
      {categories.map((category) => (
        <CategoryItem key={category.categoryId} category={category} />
      ))}
    </ul>
  );
};

const Header = ({ cartData }) => {
  const CREDIT_ID = 10;
  const headerLogoUrl = process.env.PUBLIC_URL + "/images/logo.webp";
  const [categories, setCategories] = useState([]);
  const [isOverlayVisible, setOverlayVisibility] = useState(false);
  const [menus, setMenus] = useState([]);

  const [mobileCat, setMobileCat] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [infoMenu, setInfoMenu] = useState(false);
  const [mobileCart, setMobileCart] = useState(false);
  const [favoritesOpen, setFavoritesOpen] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+994");
  const [userInput, setUserInput] = useState("");
  const [isCodeModalOpen, setIsCodeModalOpen] = useState(false);
  const [isCreditModalOpen, setIsCreditModalOpen] = useState(false);
  const [code, setCode] = useState("");
  const [finCode, setFinCode] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLikedModalOpen, setIsLikedModalOpen] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);
  const [showMenu, setShowMenu] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [typingIndex, setTypingIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("");
  const { likedProducts, setLikedProducts, basket, setBasket } =
    useStateContext();
  const { lang, setLang, setToken, setUser } = useStateContext();
  const [likedProductList, setLikedProductList] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [blurTimeout, setBlurTimeout] = useState(null);
  const navigate = useNavigate();
  const [creditLoginOpen, setCreditLoginOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const handleLogout = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refreshToken");
    window.location.reload();
  };

  const basketData = Object.entries(basket || []);

  let totPrice = 0;

  const formattedData = basketData.map(([productId, productCount]) => ({
    productSlug: productId,
    productCount: parseInt(productCount),
  }));

  const removeFromBasket = (slug) => {
    const basket = JSON.parse(localStorage.getItem("basket")) || {};

    if (basket.hasOwnProperty(slug)) {
      delete basket[slug];
      localStorage.setItem("basket", JSON.stringify(basket));
      setBasket(basket);
    } else {
      alert("Bu məhsul sepetdə yoxdur");
    }
  };

  const homeProductDataFetch = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/Product/get-all-products?LanguageCode=${lang}&pageIndex=${page}`
      );

      const data = await response.json();
      if (data.data.items.length > 0) {
        setProducts((prev) => {
          const newProducts = [...prev, ...data.data.items];
          const uniqueProducts = Array.from(
            new Set(newProducts.map((product) => product.productId))
          ).map((productId) =>
            newProducts.find((product) => product.productId === productId)
          );
          sessionStorage.setItem("products", JSON.stringify(uniqueProducts));
          return uniqueProducts;
        });
        setHasMorePages(true);
      } else {
        setHasMorePages(false);
      }
    } catch (error) {
      setHasMorePages(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    homeProductDataFetch();
  }, [page]);

  {
    /**Liked Products */
  }
  useEffect(() => {
    const fetchProductData = async () => {
      const productList = [];

      for (const slug of likedProducts) {
        const response = await axiosInstance.get(
          `api/Product/get-product?lang=${lang}&Slug=${slug}`
        );
        console.log(response.data);
        if (response.data.isSuccessful) {
          productList.push({
            id: response.data.data.productId,
            cover_image: response.data.data.coverImage,
            product_title: response.data.data.productTitle,
            slug: response.data.data.slug,
          });
        }
      }

      setLikedProductList(productList);
    };

    fetchProductData();
  }, [likedProducts]);

  const removeLikedProductList = (id) => {
    setLikedProducts((prevLikedProducts) => {
      let updatedLikedProducts;
      updatedLikedProducts = prevLikedProducts.filter((slug) => slug !== id);

      localStorage.setItem(
        "likedProducts",
        JSON.stringify(updatedLikedProducts)
      );

      return updatedLikedProducts;
    });
  };

  {
    /**Liked Products */
  }
  const typingSpeed = 100; // Speed in milliseconds

  //random text effect
  useEffect(() => {
    if (categories && categories.length > 0) {
      if (!currentCategory) {
        const allTitles = categories.reduce((titles, category) => {
          titles.push(category.categoryTitle);
          category.childCategories.forEach((childCategory) => {
            titles.push(childCategory.categoryTitle);
          });
          return titles;
        }, []);
        // Select a random category title
        const randomIndex = Math.floor(Math.random() * allTitles.length);
        setCurrentCategory(allTitles[randomIndex]);
      } else {
        const timer = setTimeout(() => {
          if (!isDeleting && typingIndex < currentCategory.length) {
            setSearchValue(
              (prevValue) => prevValue + currentCategory.charAt(typingIndex)
            );
            setTypingIndex((prevIndex) => prevIndex + 1);
          } else if (isDeleting && typingIndex > 0) {
            setSearchValue((prevValue) =>
              prevValue.substring(0, prevValue.length - 1)
            );
            setTypingIndex((prevIndex) => prevIndex - 1);
          }

          if (typingIndex === currentCategory.length) {
            setIsDeleting(true);
          } else if (typingIndex === 0 && isDeleting) {
            setIsDeleting(false);
            setCurrentCategory(""); // Reset the current category to select a new one
          }
        }, typingSpeed);

        return () => clearTimeout(timer);
      }
    }
  }, [searchValue, typingIndex, isDeleting, currentCategory, categories]);

  //random text effect

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const visible = scrollPos > currentScrollPos;

      setScrollPos(currentScrollPos);
      setShowMenu(visible);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPos]);

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  function openCreditModal() {
    setIsCreditModalOpen(true);
  }
  function closeCreditModal() {
    setIsCreditModalOpen(false);
    return navigate("/");
  }

  function handleSubmit(e) {
    e.preventDefault();
    const formattedInput = `${selectedCountryCode}-${userInput.slice(
      0,
      2
    )}-${userInput.slice(2, 5)}-${userInput.slice(5, 7)}-${userInput.slice(7)}`;
    console.log(formattedInput);

    fetch(`${process.env.REACT_APP_BASE_URL}/api/auth/get-code`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phoneNumber: formattedInput,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setModalIsOpen(false);
          setIsCodeModalOpen(true);
        }
        return response.json();
      })
      .then((data) => console.log(data))
      .catch((error) => console.error("Error:", error));
  }

  function handleSubmitCode(e) {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_BASE_URL}/api/auth/confirm-code`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: code,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.isSuccessful) {
          localStorage.setItem("access_token", data.data.token);
          localStorage.setItem("refreshToken", data.data.refreshToken);
          localStorage.setItem("user", JSON.stringify(data.data.userModel));
          setIsLoggedIn(true);
          setIsCodeModalOpen(false);
          window.location.reload();
        }
      })
      .catch((error) => console.error("Error:", error));
  }
  //Check if user is logged in
  //TODO: Need to add token verification logic here (Refresh token)
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_ADMIN_URL}/api/Language/get-all-languages`)
      .then((response) => response.json())
      .then((data) => setLanguages(data.data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    const fetchMenus = async () => {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_ADMIN_URL}/api/Menu/get-all-menus-by-type?menuTypeId=2`
      );
      if (response.data.isSuccessful) {
        const menusWithLinks = await Promise.all(
          response.data.data.map(async (menu) => {
            let link = "#";
            if (menu.menuContentTypeTitle === "Sərbəst link") {
              const response = await axiosInstance.get(
                `${process.env.REACT_APP_ADMIN_URL}/api/Menu/get-menu/${menu.menuId}`
              );
              if (response.data.isSuccessful) {
                const languageId = languages.find(
                  (language) => language.languageCode === lang
                )?.languageId;
                const menuData = response.data.data.menuTranslates.find(
                  (translate) => translate.languageId === languageId
                );
                link = menuData ? menuData.menuContent : "";
                if (
                  !link.startsWith("http://") &&
                  !link.startsWith("https://")
                ) {
                  link = "https://" + link;
                }
              }
            } else if (menu.menuContentTypeTitle === "Html kontent") {
              link = `/${lang}/menu-page/${menu.menuId}`;
            }
            return { ...menu, link };
          })
        );
        setMenus(menusWithLinks);
      }
    };

    if (languages.length > 0) {
      fetchMenus();
    }
  }, [languages, lang]);

  const toggleMobileCat = () => {
    setMobileCat(!mobileCat);
  };

  const toggleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const toggleInfoMenu = () => {
    setInfoMenu(!infoMenu);
  };

  const toggleCreditLogin = () => {
    setCreditLoginOpen(!creditLoginOpen);
  };

  const toggleMobileCart = () => {
    setMobileCart(!mobileCart);
  };

  const handleMouseOver = () => {
    setOverlayVisibility(true);
  };

  const handleMouseOut = () => {
    setOverlayVisibility(false);
  };

  const toggleFavorites = () => {
    setFavoritesOpen(!favoritesOpen);
  };

  useEffect(() => {
    f_categories();
  }, []);

  const f_categories = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/Category/get-all-categories?lang=az`
      );
      const data = await response.json();
      setCategories(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/Product/search?keyWord=${searchQuery}&lang=${lang}&pageSize=100`
      )
        .then((response) => response.json())
        .then((data) => {

          const sortedItems = data.data.items.sort((a, b) => {
            const aContains = a.productTitle.toLowerCase().includes(searchQuery.toLowerCase());
            const bContains = b.productTitle.toLowerCase().includes(searchQuery.toLowerCase());
            // Prioritize items where productTitle contains 'a55'
            if (aContains && !bContains) {
              return -1; // a comes before b
            } else if (!aContains && bContains) {
              return 1; // b comes before a
            } else {
              return 0; // No change in order
            }
          });

          // Limit the results to 3
          const limitedResults = sortedItems.slice(0, 3);
          setSearchResults(limitedResults);
          console.log("limited:", limitedResults);
        });
    } else {
      setSearchResults([]);
    }
  }, [searchQuery, lang]);

  {
    /**Credit payment */
  }
  const handleSubmitFinCode = (event) => {
    event.preventDefault();
    if (finCode.trim() === "") {
      alert("Lütfen geçerli bir değer girin");
    } else {
      closeCreditModal();
      const processedFinCode = finCode.trim().toUpperCase();
      setFinCode(processedFinCode);
      const key = CryptoJS.enc.Utf8.parse("dOsTtEcH.aZ12345");
      const iv = CryptoJS.enc.Utf8.parse("dOsTtEcH.aZ12345");

      const encrypted = CryptoJS.AES.encrypt(processedFinCode, key, { iv: iv });
      const base64 = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
      const urlSafeBase64 = base64.replace(/\+/g, "%2B").replace(/\//g, "%2F");

      let decodedFinCode = decodeURIComponent(
        encodeURIComponent(urlSafeBase64)
      );
      decodedFinCode = decodedFinCode
        .replace(/\+/g, "%2B")
        .replace(/\//g, "%2F");

      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/CreditInfo/get-credit-contract?contract_key=${decodedFinCode}`
      )
        .then((response) => {
          if (response.ok) {
            navigate(
              `/${lang}/credit-payment?finCode=${encodeURIComponent(
                urlSafeBase64
              )}`
            );
          } else if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          if (error.message.includes("400")) {
            alert("Sehv kod daxil etdiniz. Zehmet olmasa yeniden cehd edin");
            navigate("/");
          }
        });
    }
  };

  const handleChildClick = (e, childCategoryId) => {
    e.stopPropagation();
    navigate(
      `/${lang}/searchResult?categoryId=${childCategoryId}&SortType=orderbydescdate`
    );
  };

  const navigateToNew = () => {
    navigate(`/${lang}/searchResult`);
  };

  const { t } = useTranslation();

  return (
    <>
      <header className="header navbar-area">
        <div className="topbar d-none d-lg-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-10 col-md-10 col-8">
                <div className="nav-social flex">
                  <div className="header_social_icon">
                    <a href="https://www.facebook.com/dosttech/">
                      <IoLogoFacebook />
                    </a>
                  </div>
                  <div className="header_social_icon">
                    <a href="https://www.instagram.com/dosttech_/">
                      <IoLogoInstagram />
                    </a>
                  </div>
                  <div className="header_social_icon">
                    <a href="https://www.youtube.com/channel/UCNNiZZxvfNHZPBbHb6pAj7Q">
                      <IoLogoYoutube />
                    </a>
                  </div>
                  <div className="header_social_icon">
                    <a href="https://api.whatsapp.com/send?phone=994772551221">
                      <IoLogoWhatsapp />
                    </a>
                  </div>
                  <div className="header_social_icon">
                    <a href="https://www.tiktok.com/@dosttech_">
                      <IoLogoTiktok />
                    </a>
                  </div>
                  <div className="header_social_icon">
                    <a href="https://www.linkedin.com/company/dost-tech-ltd/">
                      <IoLogoLinkedin />
                    </a>
                  </div>
                  <div className="header_social_icon">
                    <a href="https://twitter.com/DDosttech">
                      <IoLogoTwitter />
                    </a>
                  </div>
                  <div className="header_social_icon">
                    <a href="https://t.me/dost_tech">
                      <IoNavigateCircle />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-2 col-4">
                <div className="top-end flex">
                  <div className=" me-2">
                    <select
                      style={{
                        backgroundColor: "#302E2B",
                        color: "white",
                        border: "none",
                      }}
                    >
                      {Array.isArray(languages) &&
                        languages.map((language, index) => (
                          <option value={index} selected={index === 0}>
                            {language.languageCode}
                          </option>
                        ))}
                    </select>
                  </div>
                  {isLoggedIn ? (
                    <>
                      <li class="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <IoPersonCircleSharp
                            className="text-white"
                            size={28}
                          />
                        </a>
                        <ul className="dropdown-menu">
                          <li className="w-100 m-0">
                            <Link
                              to="/az/profile"
                              className="dropdown-item w-100"
                            >
                              {/* {JSON.parse(localStorage.getItem("user")).userName} */}
                              Tüm siparişler
                            </Link>
                          </li>
                          <li className="w-100 m-0">
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleLogout()}
                            >
                              Logout
                            </a>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    // <Link to="/az/profile" className="text-white fw-semibold">
                    //   {/* {JSON.parse(localStorage.getItem("user")).userName} */}
                    //   <IoPersonCircleSharp size={28} />
                    // </Link>
                    <a
                      className="text-decoration-none text-white ms-2"
                      href="#"
                      onClick={openModal}
                    >
                      {t("login")}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/**Mobile topbar elements */}
        <div className="topbar d-block d-lg-none">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 text-center">
                <a
                  className="text-decoration-none text-white ms-2 topbar-link"
                  href="/az/business"
                  style={{
                    color: "#FFAA00",
                    borderLeft: "1px solid white",
                    borderRight: "1px solid white",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  Korporativ Satış
                </a>
              </div>

              {/*}<div className="col-lg-4 col-md-4 col-4 text-end">
                <a className="text-decoration-none text-white ms-2" href="">
                  {t("login")}
                </a>
                </div>{*/}
            </div>
          </div>
        </div>
        {/**Mobile topbar elements */}

        <div className="header-middle d-none d-lg-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-3 col-7">
                <Link className="navbar-brand" to="/az">
                  <img
                    src={headerLogoUrl}
                    className="header_logo"
                    alt="Dosttech Logo"
                  />
                </Link>
              </div>
              <div className="col-lg-5 col-md-7 d-xs-none">
                <div className="main-menu-search">
                  <div className="navbar-search search-style-5">
                    <div className="search-input">
                      <input
                        type="text"
                        value={searchQuery}
                        placeholder={searchValue}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onBlur={() => {
                          const timeout = setTimeout(() => {
                            setIsFocused(false);
                          }, 200);
                          setBlurTimeout(timeout);
                        }}
                        onFocus={() => {
                          clearTimeout(blurTimeout);
                          setIsFocused(true);
                        }}
                      />
                    </div>
                    <div className="search-btn">
                      <button
                        onClick={() =>
                          navigate(
                            `/${lang}/searchResult?keyWord=${searchQuery}`
                          )
                        }
                      >
                        <IoSearch />
                      </button>
                    </div>
                    {isFocused && searchResults.length > 0 && (
                      <ul className="search-results">
                        {searchResults.map((product) => (
                          <li key={product.id}>
                            <img
                              src={product.coverImage}
                              alt={product.productTitle}
                              className="search-product-image"
                            />
                            <Link to={`/${lang}/product/${product.slug}`}>
                              {product.productTitle}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
              {/**Liked Products jsx */}
              <div className="col-lg-4 col-md-2 col-5">
                <div className="middle-right-area">
                  <div className="nav-hotline">
                    <a href="tel:1221" style={{ textDecoration: "none" }}>
                      <IoCallOutline />
                      <h3>*1221</h3>
                    </a>
                  </div>
                  <div className="liked-products">
                    <div className="liked-list">
                      <a href="javascript:void(0)">
                        <IoHeartOutline />
                        <span className="total-items">
                          {likedProducts.length}
                        </span>
                        {
                          <div className="likeModal">
                            {likedProductList.length > 0 ? (
                              likedProductList.map((product) => (
                                <div
                                  key={product.id}
                                  className="likeModal-item"
                                >
                                  <img
                                    src={product.cover_image}
                                    alt={product.product_title}
                                  />
                                  <div className="w-100 d-flex align-items-center justify-content-between gap-2">
                                    <div className="title">
                                      <a
                                        href={`/${lang}/product/${product.slug}`}
                                      >
                                        {product.product_title}
                                      </a>
                                    </div>
                                    <div className="price">{product.price}</div>
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() =>
                                        removeLikedProductList(product.slug)
                                      }
                                    >
                                      X
                                    </button>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div style={{ color: "#555", fontSize: "1rem" }}>
                                Beyenilenler boşdur
                              </div>
                            )}
                          </div>
                        }
                      </a>
                    </div>
                    {/**Liked products jsx */}
                    <div className="cart-items">
                      <div className="main-btn">
                        <IoCartOutline />
                        <span className="total-items">{basketData.length}</span>
                      </div>
                      <div className="shopping-item">
                        <div className="dropdown-cart-header">
                          <span>{basketData.length} Məhsul</span>
                          <a href="/az/cart">Gör</a>
                        </div>
                        <ul className="shopping-list p-0 m-0">
                          {cartData.length > 0 ? (
                            cartData.map((item, index) => {
                              const filteredItem = formattedData.find(
                                (filtered) => filtered.productSlug === item.slug
                              );
                              const value = filteredItem?.productCount;
                              totPrice += item.price * parseInt(value);
                              return (
                                <li key={index}>
                                  <button
                                    className="remove bg-danger text-white"
                                    title="Remove this item"
                                    onClick={() => removeFromBasket(item.slug)}
                                  >
                                    <IoClose />
                                  </button>
                                  <div className="cart-img-head">
                                    <a
                                      className="cart-img"
                                      href={`/az/product/${item.slug}`}
                                    >
                                      <img src={item.coverImage} alt="#" />
                                    </a>
                                  </div>

                                  <div className="content">
                                    <small className="text-black">
                                      <a
                                        className="text-black"
                                        href={`/az/product/${item.slug}`}
                                      >
                                        {item.productTitle}
                                      </a>
                                    </small>
                                    <p className="quantity">
                                      {value}x -{" "}
                                      <span className="amount">
                                        {item.price}
                                      </span>
                                    </p>
                                  </div>
                                </li>
                              );
                            })
                          ) : (
                            <p>Sepet boş!</p>
                          )}
                        </ul>
                        <div className="bottom">
                          <div className="total">
                            <span>Cəmi:</span>
                            <span className="total-amount">{totPrice} AZN</span>
                          </div>
                          <div className="button">
                            <Link className="btn animate" to="/az/cart">
                              Ödəniş et
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-middle-container d-block d-lg-none">
          <div className="container">
            <div className="row align-items-center">
              {/**Mobile Header Logo */}
              <div className="col-lg-4 col-md-4 col-6">
                <Link className="navbar-brand" to="/az">
                  <img
                    src={headerLogoUrl}
                    style={{ width: "70%", margin: "10px" }} // Adjust width and add margin
                    className="logo-small"
                    alt="Dosttech Logo"
                  />
                </Link>
              </div>
              {/**Mobile Header Logo */}
              {/*Mobile Phone Call */}
              <div className="col-lg-4 col-md-4 col-3 d-flex justify-content-center align-items-center mr-auto">
                <Link
                  to="tel:*1221"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "20px",
                    fontFamily: "Arial, sans-serif",
                    fontWeight: "bold",
                    marginLeft: "20px",
                  }}
                  className="d-flex align-items-center ml-auto"
                >
                  {" "}
                  {/* Add marginLeft */}
                  <IoCallOutline color="black" className="mr-2" />
                  <span style={{ color: "#CCCC00" }}>*</span>
                  <span style={{ color: "#00008B" }}>1221</span>
                </Link>
              </div>
              {/*Mobile Phone Call */}
              {/**Mobile Cart */}
              <div className="col-lg-4 col-md-4 col-3 d-flex justify-content-end">
                <div
                  className="d-flex align-items-center text-center ml-auto"
                  style={{ borderLeft: "1px solid gray" }}
                >
                  <div className="mb-1">
                    <IoHeartOutline
                      className="mx-1 fs-3"
                      onClick={toggleFavorites}
                    />
                  </div>
                  <div className="mb-1" onClick={toggleMobileCart}>
                    <IoCartOutline className="mx-1 fs-3" />
                  </div>
                </div>
              </div>
              {/**Mobile Cart */}
            </div>
          </div>
        </div>

        <div className="main_menu d-none d-lg-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="nav-inner">
                  <div
                    className="mega-category-menu"
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                  >
                    <IoApps className="text-white me-2" />{" "}
                    <span className="cat-button">Kateqoriyalar</span>
                    <ul className="sub-category">
                      {categories.map((category) => (
                        <li
                          key={category.categoryId}
                          onClick={() =>
                            navigate(
                              `/${lang}/searchResult?categoryId=${category.categoryId}&SortType=orderbydescdate`
                            )
                          }
                        >
                          <a
                            href="#"
                            className="text-decoration-none d-flex justify-content-between"
                          >
                            <img
                              style={{ width: "16px", height: "auto" }}
                              src={category.categoryImage}
                              alt={category.categoryTitle}
                            />
                            {category.categoryTitle}
                            <IoCaretForward className="float-right" />
                          </a>

                          {category.childCategories.length > 0 && (
                            <ul className="inner-sub-category">
                              {category.childCategories.map((childCategory) => (
                                <li
                                  key={childCategory.categoryId}
                                  onClick={(e) =>
                                    handleChildClick(
                                      e,
                                      childCategory.categoryId
                                    )
                                  }
                                >
                                  <a href="#" className="text-decoration-none">
                                    {childCategory.categoryTitle}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div
                    className={`fullscreen-menu-overlay ${
                      isOverlayVisible ? "visible" : ""
                    }`}
                  ></div>
                  <nav className="navbar navbar-expand-lg">
                    <div
                      className="m_page_short_cats text-white d-block d-lg-none"
                      onClick={toggleMobileCat}
                    >
                      <IoApps className="mx-3 fs-4 " /> Kateqoriya
                    </div>

                    <span
                      className="d-block d-lg-none"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <IoMenu className="mx-3 fs-3 text-white" />
                    </span>

                    <div
                      className="collapse navbar-collapse sub-menu-bar"
                      id="navbarSupportedContent"
                    >
                      <ul id="nav" className="navbar-nav ms-auto">
                        {menus.map((menu) => {
                          if (menu.menuId === CREDIT_ID) {
                            return (
                              <li className="nav-item" key={menu.menuId}>
                                <Link
                                  to="#"
                                  className="text-decoration-none"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    setIsCreditModalOpen(true);
                                    navigate("az/aylik-odenis");
                                  }}
                                >
                                  {menu.menuTitle}
                                </Link>
                              </li>
                            );
                          }

                          return (
                            <li className="nav-item" key={menu.menuId}>
                              <Link
                                to={menu.link}
                                className="text-decoration-none"
                              >
                                {menu.menuTitle}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main_menu d-block d-lg-none p-2">
          <div className="container">
            <div class="input-group align-items-center">
              <div className="col-lg-2 d-flex justify-content-center">
                <div className="text-center" onClick={toggleMobileMenu}>
                  <div className="icon-container d-flex flex-column align-items-center">
                    <IoMenu color="white" className="mx-3 fs-3" />
                    <span className="menu-text text-white fw-bold">MENU</span>
                  </div>
                  {/*}<div className="fs-6">Menyu</div>{*/}
                </div>
              </div>
              <input
                type="text"
                class="form-control"
                className="form-control col-10"
                value={searchQuery}
                placeholder={searchValue}
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                onChange={(e) => setSearchQuery(e.target.value)}
                onBlur={() => {
                  const timeout = setTimeout(() => {
                    setIsFocused(false);
                  }, 200);
                  setBlurTimeout(timeout);
                }}
                onFocus={() => {
                  clearTimeout(blurTimeout);
                  setIsFocused(true);
                }}
              />
              <button
                class="btn btn-light"
                type="button"
                id="button-addon2"
                onClick={() =>
                  navigate(`/${lang}/searchResult?keyWord=${searchQuery}`)
                }
              >
                <IoSearch />
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Category Div Start */}
      <div className={`mobile_cat ${mobileCat ? "open" : ""}`}>
        <div className="mobile_cat_header d-flex align-items-center">
          <div className="col-12">
            <div className="row">
              <div className="col-3"></div>
              <div className="col-6 d-flex align-items-center justify-content-center color_w">
                <IoApps /> <b className="ms-1">Kateqoriyalar</b>
              </div>
              <div
                className="col-3 d-flex align-items-center justify-content-end"
                onClick={toggleMobileCat}
              >
                <IoClose className="mx-3 fs-3" />
              </div>
            </div>
          </div>
        </div>
        <CategoryList categories={categories} />
      </div>
      {/* Mobile Category Div Stop */}

      {/* Mobile Cart Div Start */}
      <div className={`mobile_cat ${mobileCart ? "open" : ""}`}>
        <div className="mobile_cat_header d-flex align-items-center">
          <div className="col-12">
            <div className="row">
              <div className="col-3"></div>
              <div className="col-6 d-flex align-items-center justify-content-center color_w">
                <IoCartOutline /> <b className="ms-1">Səbət</b>
              </div>
              <div
                className="col-3 d-flex align-items-center justify-content-end"
                onClick={toggleMobileCart}
              >
                <IoClose className="mx-3 fs-3" />
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 text-center">
          <div className="w-100 shopping-item">
            <div className="dropdown-cart-header d-flex align-items-center justify-content-between">
              <span>{basketData.length} Məhsul</span>
              <a href="/az/cart">Gör</a>
            </div>
            <ul className="shopping-list p-0 m-0 my-5 d-flex flex-column align-items-center justify-content-center gap-3">
              {cartData ? (
                cartData.map((item, index) => {
                  const filteredItem = formattedData.find(
                    (filtered) => filtered.productSlug === item.slug
                  );
                  const value = filteredItem?.productCount;
                  return (
                    <li
                      key={index}
                      className="w-100 d-flex align-items-center justify-content-between gap-3"
                    >
                      <div
                        className="cart-img-head"
                        style={{ maxWidth: "120px" }}
                      >
                        <a
                          className="cart-img"
                          href={`/az/product/${item.slug}`}
                        >
                          <img
                            src={item.coverImage}
                            alt="#"
                            className="img-thumbnail"
                          />
                        </a>
                      </div>

                      <div className="content flex-grow-1 text-start">
                        <h6 className="">
                          <a href={`/az/product/${item.slug}`}>
                            {item.productTitle}
                          </a>
                        </h6>
                        <p className="quantity">
                          {value}x -{" "}
                          <span className="amount">{item.price}</span>
                        </p>
                      </div>
                    </li>
                  );
                })
              ) : (
                <p>Sepet boş!</p>
              )}
            </ul>
            <div className="bottom d-flex align-items-center justify-content-between">
              <div className="total">
                <span>Cəmi:</span>
                <span className="total-amount">{totPrice} AZN</span>
              </div>
              <div className="button">
                <a className="btn animate" href="/az/cart">
                  Ödəniş et
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile Cart Div Stop */}

      {/* Mobile Favorites */}

      <div className={`mobile_cat ${favoritesOpen ? "open" : "close"}`}>
        <div className="mobile_cat_header d-flex align-items-center">
          <div className="col-12">
            <div className="row">
              <div className="col-3"></div>
              <div className="col-6 d-flex align-items-center justify-content-center color_w">
                <IoHeartOutline /> <b className="ms-1">Bəyəndiklərim</b>
              </div>
              <div
                className="col-3 d-flex align-items-center justify-content-end"
                onClick={toggleFavorites}
              >
                <IoClose className="mx-3 fs-3" />
              </div>
            </div>
          </div>
        </div>
        <div className="p-5 text-center">
          {likedProducts ? (
            likedProductList.map((product) => (
              <div key={product.id} className="likeModal-item">
                <img src={product.cover_image} alt={product.product_title} />
                <div className="w-100 d-flex align-items-center justify-content-between gap-2">
                  <div className="title">
                    <a href={`/${lang}/product/${product.slug}`}>
                      {product.product_title}
                    </a>
                  </div>
                  <div className="price">{product.price}</div>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => removeLikedProductList(product.slug)}
                  >
                    X
                  </button>
                </div>
              </div>
            ))
          ) : (
            <b>Səbətiniz boşdur</b>
          )}
        </div>
      </div>
      {/* Mobile Favorites */}

      {/* Mobile Menu Div Start */}
      <div className={`mobile_menu ${mobileMenu ? "open" : ""}`}>
        <div className="mobile_menu_header d-flex align-items-center">
          <div className="col-12">
            <div className="row">
              <div
                className="col-3 d-flex align-items-center"
                onClick={toggleMobileMenu}
              >
                <IoClose className="mx-3 fs-3" />
              </div>
              <div className="col-6 d-flex align-items-center justify-content-center color_w">
                <b>Menu</b>
              </div>
              <div className="col-3 d-flex align-items-center justify-content-end">
                <div className="t_select_position me-2">
                  <select id="select">
                    <option value="0" selected>
                      Az
                    </option>
                    <option value="1">En</option>
                    <option value="2">Ru</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ul className="mobile_menu_lists list-unstyled ps-0">
          <li onClick={toggleMobileCat}>
            <div className="d-flex justify-content-between align-items-center c_pointer">
              <div className="d-flex align-items-center p-2">
                <span>
                  {" "}
                  <IoApps className="fs-4" /> Kateqoriyalar
                </span>
              </div>
            </div>
          </li>
          <li onClick={toggleFavorites}>
            <div className="d-flex justify-content-between align-items-center c_pointer">
              <div className="d-flex align-items-center p-2">
                <span>
                  <IoHeartOutline className="fs-4" /> Bəyəndiklərim
                </span>
              </div>
            </div>
          </li>
          <li>
            <div className="d-flex justify-content-between align-items-center c_pointer">
              <div
                className="d-flex align-items-center p-2"
                onClick={() => {
                  navigate(`/${lang}/comparison`);
                  toggleMobileMenu();
                }}
              >
                <span>
                  <IoSwapHorizontal className="fs-4" /> Müqayisə
                </span>
              </div>
            </div>
          </li>
          <li>
            <div
              className="d-flex justify-content-between align-items-center c_pointer"
              onClick={() => {
                openCreditModal();
                toggleMobileMenu();
              }}
            >
              <div
                className="d-flex align-items-cente p-2"
                onClick={(event) => {
                  event.preventDefault();
                  setIsCreditModalOpen(true);
                  navigate("az/aylik-odenis");
                }}
              >
                <span>
                  <IoWalletOutline className="fs-4" /> Aylıq Ödəniş
                </span>
              </div>
            </div>
          </li>
          <li>
            <div className="d-flex justify-content-between align-items-center c_pointer">
              <div className="d-flex align-items-center p-2">
                <span>
                  <IoPersonOutline className="fs-4" /> Daxil ol
                </span>
              </div>
            </div>
          </li>
          <li onClick={toggleInfoMenu}>
            <div className="d-flex justify-content-between align-items-center c_pointer">
              <div className="d-flex align-items-center p-2">
                <span>
                  <IoInformationCircleOutline className="fs-4" /> Məlumat /
                  Yardım{" "}
                </span>
              </div>
            </div>
          </li>
        </ul>
        <div className="text-center my-3 py-2">
          <b>Bizi izləyin</b>
        </div>
        <div className="row">
          <div className="col-3 text-center my-3">
            <a href="javascript:void(0)">
              <IoLogoFacebook className="fs-2 color_facebook" />
            </a>
          </div>
          <div className="col-3 text-center  my-3">
            <a href="javascript:void(0)">
              <IoLogoInstagram className="fs-2 color_instagram" />
            </a>
          </div>
          <div className="col-3 text-center  my-3">
            <a href="javascript:void(0)">
              <IoLogoYoutube className="fs-2 color_youtube" />
            </a>
          </div>
          <div className="col-3 text-center my-3">
            <a href="javascript:void(0)">
              <IoLogoWhatsapp className="fs-2 color_whatsapp" />
            </a>
          </div>

          <div className="col-3 text-center  my-3">
            <a href="javascript:void(0)">
              <IoLogoLinkedin className="fs-2 color_linkedin" />
            </a>
          </div>
          <div className="col-3 text-center  my-3">
            <a href="javascript:void(0)">
              <IoLogoTwitter className="fs-2 color_twitter" />
            </a>
          </div>
          <div className="col-3 text-center  my-3">
            <a href="javascript:void(0)">
              <IoLogoTiktok className="fs-2 color_tiktok" />
            </a>
          </div>
          <div className="col-3 text-center  my-3">
            <a href="javascript:void(0)">
              <IoNavigateCircle className="fs-2 color_telegram" />
            </a>
          </div>
        </div>
      </div>
      {/* Mobile Menu Div Stop */}

      {/* Mobile Menu Div Start */}
      <div className={`mobile_cat ${infoMenu ? "open" : ""}`}>
        <div className="mobile_cat_header d-flex align-items-center">
          <div className="col-12">
            <div className="row">
              <div className="col-3 d-flex align-items-center"></div>
              <div className="col-6 d-flex align-items-center justify-content-center color_w">
                <IoInformationCircleOutline />{" "}
                <b className="ms-1">Məlumat / Yardım</b>
              </div>
              <div
                className="col-3 d-flex align-items-center  justify-content-end"
                onClick={toggleInfoMenu}
              >
                <IoClose className="mx-3 fs-3" />
              </div>
            </div>
          </div>
        </div>

        <ul className="mobile_cat_lists list-unstyled ps-0">
          <li>
            <div className="d-flex justify-content-between align-items-center c_pointer">
              <div className="d-flex align-items-center p-2">
                <span>Şirkət Haqqında</span>
              </div>
            </div>
          </li>
          <li>
            <div className="d-flex justify-content-between align-items-center c_pointer">
              <div className="d-flex align-items-center p-2">
                <span>Kredit Şərtləri</span>
              </div>
            </div>
          </li>
          <li>
            <div className="d-flex justify-content-between align-items-center c_pointer">
              <div className="d-flex align-items-center p-2">
                <span>Çatdırılma Şərtləri</span>
              </div>
            </div>
          </li>
          <li>
            <div className="d-flex justify-content-between align-items-center c_pointer">
              <div className="d-flex align-items-center p-2">
                <span>Ödəniş Üsulları</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      {/* Mobile Menu Div Stop */}

      {/* Sisteme giriş birinci aşama modal başlangıç*/}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(255, 255, 255, 0.95)",
            zIndex: 1000,
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "400px",
            textAlign: "center",
            "@media (max-width: 600px)": {
              width: "70%", // Set the width to 80% of the screen width
              height: "80%",
            },
          },
        }}
      >
        <div style={{ position: "relative" }}>
          <XMarkIcon
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              width: "20px",
              height: "20px",
            }}
            onClick={closeModal}
          />
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <h1 style={{ fontWeight: "bold" }}>DOSTTECH</h1>
            <p style={{ color: "gray" }}>KABİNETƏ GİRİŞ</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/*}<Select
                options={countryOptions}
                components={{ Option: CustomOption }}
                onChange={selectedOption => {
                  setSelectedCountryCode(selectedOption.value);
                  setInputMask(`${selectedOption.value}-99-999-99-99`);
                }}
                defaultValue={defaultOption}
                menuPlacement="auto"
              />{*/}
              <InputMask
                mask="99-999-99-99"
                value={userInput}
                onChange={(event) =>
                  setUserInput(event.target.value.replace(/\D/g, ""))
                }
                maskChar={null}
                style={{
                  margin: "10px",
                  padding: "10px",
                  border: "1px solid gray",
                  borderRadius: "5px",
                }}
              />
            </div>
            <p style={{ color: "lightgray" }}>
              Numaranizi +994-**-***-**-** formatinda girin
            </p>
            <button
              type="submit"
              style={{
                backgroundColor: "#4CAF50",
                border: "none",
                color: "white",
                padding: "15px 32px",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
                fontSize: "16px",
                margin: "4px 2px",
                cursor: "pointer",
                borderRadius: "4px",
              }}
            >
              SMS Kodu Gönder
            </button>
          </form>
        </div>
      </Modal>
      {/* Sisteme giriş birinci aşama modal bitiş*/}

      {/* Sisteme giriş ikinci aşama modal başlangıç*/}
      <Modal
        isOpen={isCodeModalOpen}
        onRequestClose={() => setIsCodeModalOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(255, 255, 255, 0.95)",
            zIndex: 1000,
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "400px",
            textAlign: "center",
          },
        }}
      >
        <form onSubmit={handleSubmitCode}>
          <p style={{ color: "gray" }}>
            Lütfen telefonunuza gönderilen 4 haneli kodu girin
          </p>
          <InputMask
            mask="9999"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            style={{
              margin: "10px",
              padding: "10px",
              border: "1px solid gray",
              borderRadius: "5px",
            }}
          />
          <button
            type="submit"
            style={{
              backgroundColor: "#4CAF50",
              border: "none",
              color: "white",
              padding: "15px 32px",
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              fontSize: "16px",
              margin: "4px 2px",
              cursor: "pointer",
              borderRadius: "4px",
            }}
          >
            Kodu onayla
          </button>
        </form>
      </Modal>
      {/* Sisteme giriş ikinci aşama modal bitiş*/}

      {/* Kredit odeme modal başlangıç*/}
      <MonthlyPayment
        isOpen={isCreditModalOpen}
        onRequestClose={closeCreditModal}
        style={{
          overlay: {
            backgroundColor: "rgba(255, 255, 255, 1)",
            zIndex: 1000,
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "400px",
            textAlign: "center",
          },
        }}
      >
        <div style={{ position: "relative" }}>
          <XMarkIcon
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              width: "20px",
              height: "20px",
            }}
            onClick={closeCreditModal}
          />
          <form onSubmit={handleSubmitFinCode}>
            <h2 style={{ fontWeight: "bold" }}>Aylıq ödəniş</h2>
            <p style={{ color: "gray" }}>Fin kod*</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InputMask
                value={finCode}
                onChange={(e) => setFinCode(e.target.value)}
                style={{
                  margin: "10px",
                  padding: "10px",
                  border: "1px solid gray",
                  borderRadius: "5px",
                }}
              />
            </div>
            <button
              type="submit"
              style={{
                backgroundColor: "#0b2875",
                border: "none",
                color: "white",
                padding: "15px 32px",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
                fontSize: "16px",
                margin: "4px 2px",
                cursor: "pointer",
                borderRadius: "4px",
              }}
            >
              Daxil ol
            </button>
          </form>
        </div>
      </MonthlyPayment>
      {/* Kredi odeme modal bitiş*/}

      {/**Mobile floating menu */}
      {showMenu && (
        <div className={`floating-menu ${showMenu ? "" : "hide"}`}>
          <div onClick={() => navigate(`/${lang}/`)} className="menu-item">
            <IoHome />
            <span>Əsas Səhifə</span>
          </div>
          <div
            onClick={() => navigate(`/${lang}/contact`)}
            className="menu-item"
          >
            <IoChatbubbleEllipsesOutline />
            <span>Əlaqə</span>
          </div>
          <div onClick={toggleMobileCat} className="menu-item">
            <IoAppsOutline />
            <span>Kataloq</span>
          </div>
          <div
            className="menu-item"
            onClick={(event) => {
              event.preventDefault();
              setIsCreditModalOpen(true);
              navigate("az/aylik-odenis");
            }}
          >
            <IoCashOutline />
            <span>Aylıq ödəniş</span>
          </div>

          {isLoggedIn ? (
            <>
              <a
                className="nav-link dropdown-toggle menu-item d-flex"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <IoPersonOutline />
                <span>Profil</span>
              </a>
              <ul className="dropdown-menu">
                <li className="w-100 m-0">
                  <Link to="/az/profile" className="dropdown-item w-100">
                    {/* {JSON.parse(localStorage.getItem("user")).userName} */}
                    Tüm siparişler
                  </Link>
                </li>
                <li className="w-100 m-0">
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => handleLogout()}
                  >
                    Logout
                  </a>
                </li>
              </ul>
            </>
          ) : (
            <div className="menu-item" onClick={openModal}>
              <IoPersonOutline />
              <span>Giriş</span>
            </div>
          )}
        </div>
      )}
      {/**Mobile floating menu */}
    </>
  );
};

export default Header;
