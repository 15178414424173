import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputMask from "react-input-mask";
import { IoEye, IoEyeOff, IoPersonCircle, IoMail, IoCall } from "react-icons/io5";
import { useStateContext } from "../App";

const Sign_in = () => {
  const { setToken, setUser } = useStateContext();

  const [isSmsSent, setIsSmsSent] = useState(false);

  const [formData, setFormData] = useState({
    userPhone: "",
    userGetCode: "",
  });

  const handlePhoneNumberSubmit = async (event) => {
    event.preventDefault();

    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/get-code`, {
      phone: formData.userPhone,
    });

    setIsSmsSent(true);
    // Handle response
  };

  const handleSmsCodeSubmit = async (event) => {
    event.preventDefault();

    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/confirm-code`, {
      code: formData.userGetCode,
    });

    setToken(response.data.token);
    setUser(response.data.user);
  };

  return (
    <div>
      {!isSmsSent ? (
        <form onSubmit={handlePhoneNumberSubmit}>
          <InputMask
            mask="+994-99-999-99-99"
            value={formData.userPhone}
            onChange={(e) => setFormData({ ...formData, userPhone: e.target.value })}
          />
          <button type="submit">Send SMS</button>
        </form>
      ) : (
        <form onSubmit={handleSmsCodeSubmit}>
          <input
            type="number"
            value={formData.userGetCode}
            onChange={(e) => setFormData({ ...formData, userGetCode: e.target.value })}
          />
          <button type="submit">Confirm</button>
        </form>
      )}
    </div>
  );
};

export default Sign_in;
