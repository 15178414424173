import React, { useEffect, useState } from "react";
import { useStateContext } from "../context/ContextProvider";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";

const Profile = () => {
  const [invoices, setInvoices] = useState([]);
  const [paymentType, setPaymentType] = useState([]);
  const [cardType, setCardType] = useState([]);
  const [basketDetails, setBasketDetails] = useState({});
  const [isOpen, setIsOpen] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const { token } = useStateContext();

  const getAllInvoices = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/Invoice/get-all-invoices?lang=az`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setInvoices(data.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getInvoiceDetail = async (id) => {
    if (isOpen[id]) {
      setIsOpen({ ...isOpen, [id]: false });
      setBasketDetails({ ...basketDetails, [id]: [] });
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/Basket/get-basket-by-invoice-id?InvoiceId=${id}&lang=az`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();

        if (response.ok) {
          setIsOpen({ ...isOpen, [id]: true });
          setBasketDetails({
            ...basketDetails,
            [id]: data.data.basketDetails,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsOpen({ ...isOpen, [id]: false });
      }
    }
  };

  const handlePaymentTypes = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/PaymentType/payment-types?Lang=az`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        console.log("Ödeme türü başarıyla gönderildi");
        const data = await response.json();
        setPaymentType(data.data);
      } else {
        console.error("Ödeme türü gönderilirken bir hata oluştu");
      }
    } catch (error) {
      console.error("İstek gönderilirken bir hata oluştu:", error);
      setErrorMessage("İstek gönderilirken bir hata oluştu");
    }
  };

  const handleCardTypes = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/PaymentType/payment-card-types?Lang=az`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const data = await response.json();
      setCardType(data.data);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    getAllInvoices();
    handlePaymentTypes();
    handleCardTypes();
  }, []);

  return (
    <div className="container mt-5" style={{ minHeight: "100vh" }}>
      <h1 className="text-center mb-4">Sipariş Geçmişi</h1>
      <div className="row">
        {invoices.length > 0 ? (
          invoices.map((invoice, index) => (
            <div className="col-12" key={index}>
              <div className="card mb-4 shadow-sm">
                <div
                  className="card-body d-flex align-items-center justify-content-between gap-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => getInvoiceDetail(invoice.invoiceId)}
                >
                  <div>
                    <h5 className="card-title mb-3">
                      <span className="fw-semibold">Alıcı:</span>{" "}
                      {invoice.userName} {invoice.userSurName}
                    </h5>
                    <p className="card-text mb-2">
                      <strong>Phone:</strong> {invoice.userPhone}
                    </p>
                    <p className="card-text d-flex align-items-center gap-1 mb-2">
                      <strong>Ödeme Yöntemi:</strong>
                      <div className="d-flex align-items-center gap-2">
                        <span>
                          {paymentType.length > 0 &&
                            paymentType.map(
                              (paymentType) =>
                                paymentType.paymentTypeId ===
                                  invoice.paymentTypeId &&
                                paymentType.paymentTypeTitle
                            )}
                        </span>
                        <span>
                          {invoice.cardTypeId !== null &&
                            cardType.map(
                              (card) =>
                                card.paymentCardTypeId === 2 && (
                                  <>
                                    {" - "} {card.paymentCardTypeTitle}
                                  </>
                                )
                            )}
                        </span>
                      </div>
                    </p>
                    <p className="card-text mb-2">
                      <strong>Tutar:</strong> {invoice.invoiceFinalPrice} AZN
                    </p>
                  </div>
                  <div>
                    <IoIosArrowDown
                      style={{
                        transform: isOpen[invoice.invoiceId]
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                      size={28}
                    />
                  </div>
                </div>
                {isOpen[invoice.invoiceId] && (
                  <div className="card-footer table-responsive">
                    <h6 className="mt-2 mb-4">
                      Ürünler ({basketDetails[invoice.invoiceId]?.length || 0})
                    </h6>
                    <table className="table table-hover">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col" className="fw-semibold">
                            Ürün Resmi
                          </th>
                          <th scope="col" className="fw-semibold">
                            Ürün Başlığı
                          </th>
                          <th scope="col" className="fw-semibold">
                            Ürün Fiyatı
                          </th>
                          <th scope="col" className="fw-semibold">
                            Ürün Adedi
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {basketDetails[invoice.invoiceId]?.map(
                          (item, index) => (
                            <tr key={index}>
                              <td>
                                <img
                                  src={item.coverImage}
                                  alt={item.productTitle}
                                  className="img-thumbnail"
                                  width="50"
                                />
                              </td>
                              <td>
                                <Link
                                  to={`/az/product/${item.slug}`}
                                  className="text-dark text-decoration-none fw-semibold"
                                  style={{ fontSize: "12px" }}
                                >
                                  {item.productTitle}
                                </Link>
                              </td>
                              <td style={{ fontSize: "12px" }}>
                                {item.productBasePrice} ₼
                              </td>
                              <td style={{ fontSize: "12px" }}>
                                {item.productCount}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>

                    <h6 className="mt-4 mb-2">Diğer</h6>
                    <p className="card-text mb-2">
                      <strong>Address:</strong> {invoice.userAddress}
                    </p>
                    <p className="card-text mb-2">
                      <strong>Note:</strong> {invoice.note}
                    </p>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div>Sipariş geçmişi bulunamadı.</div>
        )}
      </div>
    </div>
  );
};

export default Profile;
