import React, { useEffect, useState } from "react";
import { useStateContext } from "../context/ContextProvider";
import LoadingAnimation from "../Components/LoadingAnimation";
import { useLocation } from "react-router-dom";

const ConfirmCart = () => {
  const { basket, setBasket } = useStateContext();
  const { lang, setLang, token } = useStateContext();

  const location = useLocation();

  const [getBasket, setGetBasket] = useState([]);
  const [paymentType, setPaymentType] = useState("");
  const [cardTypes, setCardTypes] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeTab, setActiveTab] = useState(paymentType[0]?.paymentTypeTitle);
  const [activeCardTab, setActiveCardTab] = useState(
    cardTypes[0]?.paymentCardTypeTitle
  );
  const [selectedPaymentType, setSelectedPaymentType] = useState(0);
  const [selectedCardType, setSelectedCardType] = useState(0);
  const [monthCount, setMonthCount] = useState(0);
  const [orderSummary, setOrderSummary] = useState({
    baseTotalPrice: 0,
    finalTotalPrice: 0,
    saving: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    userName: "",
    userSurName: "",
    userAddress: "",
    userPhone: "",
    note: "",
    userPaymentType: {
      paymentTypeId: selectedPaymentType,
      cardTypeId: selectedCardType,
      monthCount: monthCount,
    },
  });

  const basketData = Object.entries(location.state || []);

  let formattedData = basketData.map(([productId, productCount]) => ({
    productSlug: productId,
    productCount: parseInt(productCount),
  }));

  const [calculateBasket, setCalculateBasket] = useState({
    productBasketModel: [],
    userPaymentType: {
      paymentTypeId: 5,
      cardTypeId: 0,
      monthCount: 0,
    },
  });

  useEffect(() => {
    setCalculateBasket({
      productBasketModel: [...formattedData],
      userPaymentType: {
        paymentTypeId: 5,
        cardTypeId: 0,
        monthCount: 0,
      },
    });
  }, []);

  const handleFormValues = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setFormData({ ...formData, [name]: value });
  };

  const handlePaymentTypes = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/PaymentType/payment-types?Lang=az`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        console.log("Ödeme türü başarıyla gönderildi");
        const data = await response.json();
        setPaymentType(data.data);
      } else {
        console.error("Ödeme türü gönderilirken bir hata oluştu");
      }
    } catch (error) {
      console.error("İstek gönderilirken bir hata oluştu:", error);
      setErrorMessage("İstek gönderilirken bir hata oluştu");
    }
  };

  useEffect(() => {
    handlePaymentTypes();
  }, []);

  const handleCardTypes = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/PaymentType/payment-card-types?Lang=az`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const data = await response.json();
      setCardTypes(data.data);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    handleCardTypes();
  }, []);

  const handleGetBasket = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/Basket/get-basket?lang=az`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      if (data.isSuccessful) {
        setGetBasket(data.data);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    handleGetBasket();
  }, [token, basket]);

  const handleCalculateBasket = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/Basket/calculate-basket`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(calculateBasket),
        }
      );

      const data = await response.json();
      if (data.isSuccessful) {
        setOrderSummary(data.data);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    handleCalculateBasket();
  }, [formData, basket]);

  const handleCompleteOrder = async () => {
    try {
      const {
        userPaymentType: { paymentTypeId, cardTypeId, monthCount },
        userName,
        userSurName,
        userPhone,
        userAddress,
      } = formData;

      if (!paymentTypeId) {
        return alert("Please select a payment type!");
      }

      if (!userName || !userSurName || !userPhone || !userAddress) {
        return alert("Please fill your information!");
      }

      if (paymentTypeId === 6 && cardTypeId === 0) {
        return alert("Please select a card type!");
      }

      if (paymentTypeId === 6 && cardTypeId !== 0 && monthCount === 0) {
        return alert("Please select a month!");
      }

      if (paymentTypeId === 3) {
        setIsLoading(true);
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/Invoice/create-invoice`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(formData),
            }
          );

          const result = await response.json();

          if (result.isSuccessful) {
            localStorage.removeItem("basket");
            window.location.href = "/az/profile";
          }
        } catch (error) {
          console.error("Error:", error);
        } finally {
          setIsLoading(false);
        }
      }

      if (paymentTypeId !== 3) {
        setIsLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/Invoice/create-invoice`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
          }
        )
          .then((response) => response.text())
          .then((form) => {
            const wrapper = document.createElement("div");
            //form = form.replace(/<script>.*<\/script>/, ''); // remove script from form
            wrapper.innerHTML = form;
            document.body.appendChild(wrapper);
            console.log("Success:", form);
            document.forms["yavuz"].submit();
            setIsLoading(false);
          })
          .catch((error) => console.error("Error:", error));

        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  return (
    <div className="container pb-5" style={{ minHeight: "100vh" }}>
      <div>
        <LoadingAnimation active={isLoading} />
      </div>
      <div className="row">
        <div className="col-md-8 col-12 mt-5">
          <h5 className="mb-3">Çatdırılma məlumatları</h5>
          <form>
            <div className="row mb-3">
              <div className="col">
                <div className="form-floating">
                  <input
                    onChange={(e) => handleFormValues(e)}
                    value={formData.userName}
                    name="userName"
                    id="floatingName"
                    type="text"
                    className="form-control"
                    placeholder="Ad"
                  />
                  <label for="floatingName">Ad (*)</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating">
                  <input
                    onChange={(e) => handleFormValues(e)}
                    value={formData.userSurName}
                    name="userSurName"
                    id="floatingSurname"
                    type="text"
                    className="form-control"
                    placeholder="Soyad"
                  />
                  <label for="floatingSurname">Soyad (*)</label>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <div className="form-floating">
                  <input
                    onChange={(e) => handleFormValues(e)}
                    value={formData.userPhone}
                    name="userPhone"
                    id="floatingPhone"
                    type="tel"
                    className="form-control"
                    placeholder="Telefon nömrəsi"
                  />
                  <label for="floatingPhone">Telefon nömrəsi (*)</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating">
                  <input
                    onChange={(e) => handleFormValues(e)}
                    value={formData.userAddress}
                    name="userAddress"
                    id="floatingAddress"
                    type="text"
                    className="form-control"
                    placeholder="Ünvan"
                  />
                  <label for="floatingAddress">Ünvan (*)</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col button">
                <div className="form-floating">
                  <textarea
                    onChange={(e) => handleFormValues(e)}
                    id="floatingNote"
                    type="text"
                    className="form-control"
                    name="note"
                    placeholder="Qeyd"
                  >
                    {formData.note}
                  </textarea>
                  <label for="floatingNote">Qeyd</label>
                </div>
              </div>
            </div>
          </form>
          <hr className="my-5" />

          <h5 className="mb-3">Ödəniş üsulunu seçin</h5>
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            {paymentType &&
              paymentType.map((item, index) => (
                <li key={index} className="nav-item m-1" role="presentation">
                  <button
                    className={`${
                      activeTab === item.paymentTypeTitle
                        ? "bg-primary"
                        : "bg-secondary"
                    } nav-link active`}
                    id={`pills-${item.paymentTypeTitle}-tab`}
                    data-bs-toggle="pill"
                    data-bs-target={`#pills-${item.paymentTypeTitle}`}
                    type="button"
                    role="tab"
                    aria-controls={`pills-${item.paymentTypeTitle}`}
                    aria-selected="true"
                    onClick={() => {
                      setActiveTab(item.paymentTypeTitle);
                      setSelectedPaymentType(item.paymentTypeId);
                      setFormData({
                        ...formData,
                        userPaymentType: {
                          paymentTypeId: item.paymentTypeId,
                          cardTypeId:
                            item.paymentTypeId === 5 ? 1 : selectedCardType,
                        },
                      });
                      setCalculateBasket({
                        ...calculateBasket,
                        userPaymentType: {
                          paymentTypeId: item.paymentTypeId,
                        },
                      });
                    }}
                  >
                    {item.paymentTypeTitle}
                  </button>
                </li>
              ))}
          </ul>
          <div className="tab-content" id="pills-tabContent">
            {paymentType &&
              paymentType.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`tab-pane fade border p-3 ${
                      activeTab === item.paymentTypeTitle && "show active"
                    }`}
                    id={`pills-${item.paymentTypeTitle}`}
                    role="tabpanel"
                    aria-labelledby={`pills-${item.paymentTypeTitle}-tab`}
                    tabindex="0"
                  >
                    <small>{item.paymentTypeDescription}</small>
                    <ul
                      className="nav nav-underline mt-4"
                      id="myTab"
                      role="tablist"
                    >
                      {activeTab === "Kart ilə hissəli odəniş" &&
                        cardTypes &&
                        cardTypes.map((item, index) => (
                          <li
                            key={index}
                            className="nav-item"
                            role="presentation"
                          >
                            <button
                              className="nav-link d-flex flex-column align-items-center justify-content-center gap-2"
                              id={`${item.paymentCardTypeTitle}-tab`}
                              data-bs-toggle="tab"
                              data-bs-target={`#${item.paymentCardTypeTitle}`}
                              type="button"
                              role="tab"
                              aria-controls={`${item.paymentCardTypeTitle}-tab-pane`}
                              aria-selected="true"
                              onClick={() => {
                                setActiveCardTab(item.paymentCardTypeTitle);
                                setSelectedCardType(item.paymentCardTypeId);
                                setFormData({
                                  ...formData,
                                  userPaymentType: {
                                    cardTypeId: item.paymentCardTypeId,
                                    paymentTypeId: selectedPaymentType,
                                    monthCount: monthCount,
                                  },
                                });
                                setCalculateBasket({
                                  ...calculateBasket,
                                  userPaymentType: {
                                    cardTypeId: item.paymentCardTypeId,
                                    paymentTypeId: selectedPaymentType,
                                    monthCount: 3,
                                  },
                                });
                              }}
                            >
                              <img
                                src={item.paymentCardTypeIcon}
                                alt={item.paymentCardTypeTitle}
                                style={{ width: "48px", height: "32px" }}
                              />
                              <span>{item.paymentCardTypeTitle}</span>
                            </button>
                          </li>
                        ))}
                    </ul>
                    {activeTab === "Kart ilə hissəli odəniş" && (
                      <div class="tab-content" id="myTabContent">
                        {cardTypes &&
                          cardTypes.map((item, index) => (
                            <div
                              key={index}
                              class={`tab-pane fade border-top p-3 ${
                                activeCardTab === item.paymentCardTypeTitle &&
                                "show active"
                              }`}
                              id={`${item.paymentCardTypeTitle}-tab-pane`}
                              role="tabpanel"
                              aria-labelledby={`${item.paymentCardTypeTitle}-tab`}
                              tabindex="0"
                            >
                              {item.paymentCardCreditTypes &&
                                item.paymentCardCreditTypes
                                  .sort((a, b) => a.monthCount - b.monthCount)
                                  .map((creditType, index) => (
                                    <div class="form-check" key={index}>
                                      <input
                                        class="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        value={creditType.monthCount}
                                        onChange={(e) => {
                                          setMonthCount(creditType.monthCount);
                                          setCalculateBasket({
                                            ...calculateBasket,
                                            userPaymentType: {
                                              cardTypeId: selectedCardType,
                                              paymentTypeId:
                                                selectedPaymentType,
                                              monthCount: creditType.monthCount,
                                            },
                                          });
                                          setFormData({
                                            ...formData,
                                            userPaymentType: {
                                              cardTypeId: selectedCardType,
                                              paymentTypeId:
                                                selectedPaymentType,
                                              monthCount: creditType.monthCount,
                                            },
                                          });
                                        }}
                                        id={`flexSwitchCheck-${item.paymentCardTypeTitle}`}
                                      />
                                      <label
                                        class="form-check-label"
                                        for={`flexSwitchCheck-${item.paymentCardTypeTitle}`}
                                      >
                                        {creditType.monthCount}
                                      </label>
                                    </div>
                                  ))}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          <div
            className="tab-pane fade"
            id="payment-tab-pane"
            role="tabpanel"
            aria-labelledby="payment-tab"
            tabindex="0"
          ></div>
        </div>
        <div className="col-md-4 col-12 mt-5">
          <div className="card p-3 border-1 rounded-0">
            <div className="card-body">
              <h5 className="card-title mb-4">Sipariş Özeti</h5>
              {orderSummary && (
                <>
                  <ul className="m-0 mb-4 p-0 d-flex flex-column align-items-start gap-2">
                    <li className="w-100 d-flex align-items-center justify-content-md-between justify-content-start gap-2">
                      Məhsullar
                      <span className="fw-semibold">
                        {orderSummary.baseTotalPrice} Azn
                      </span>
                    </li>
                    <li className="w-100 d-flex align-items-center justify-content-md-between justify-content-start gap-2">
                      Çatdırılma<span className="fw-semibold">0.00 Azn</span>
                    </li>
                    <li className="w-100 d-flex align-items-center justify-content-md-between justify-content-start gap-2">
                      Qənaət
                      <span className="fw-semibold">
                        {orderSummary.saving} Azn
                      </span>
                    </li>
                    <li className="w-100 d-flex align-items-center justify-content-md-between justify-content-start gap-2">
                      Toplam
                      <span className="fw-semibold">
                        {orderSummary.finalTotalPrice} Azn
                      </span>
                    </li>
                  </ul>
                  <div className="button">
                    <button
                      className="btn w-100"
                      onClick={() => handleCompleteOrder()}
                    >
                      Siparişi Tamamla
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmCart;
