import React, { useEffect, useState } from "react";
import axios from "axios";
import ProductCard from '../Components/ProductCard';
import { Link } from "react-router-dom";
const Cart = () => {
  const [product, setProduct] = useState({});
  const url = window.location.pathname;
  const segments = url.split("/");
  let slug = segments[3];



  return (
    <>
    <div class="breadcrumbs">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 col-12">
                    <ul class="breadcrumb-nav  text-end">
                        <li><a href=""><i class="lni lni-home"></i> Dosttech</a></li>
                        <li>Şirkət haqqında</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
    <div class="page_section">
            <div class="row">
                <div class="col-12">
                    <div className="text-center mb-3">
                        <h2>Şirkət haqqında</h2>
                    </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam id purus at risus
                            pellentesque faucibus a quis eros. In eu fermentum leo. Integer ut eros lacus. Proin ut
                            accumsan leo. Morbi vitae est eget dolor consequat aliquam eget quis dolor. Mauris rutrum
                            fermentum erat, at euismod lorem pharetra nec. Duis erat lectus, ultrices euismod sagittis.
                        </p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eius mod tempor incididunt
                            ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi aliquip ex ea commodo consequat.</p>
                    
                </div>
            </div>
            <div class="row">
          
            </div>
        
    </div>
    </div>
</>
  );
};

export default Cart;
