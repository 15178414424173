import React, { useEffect, useState } from "react";
import axios from "axios";
import ProductCard from '../Components/ProductCard';
import { Link } from "react-router-dom";
const ProductDetail = () => {
  const [product, setProduct] = useState({});
  const url = window.location.pathname;
  const segments = url.split("/");
  let slug = segments[3];


  return (
    <div className="container_m">

    <section class="item-details section">
        <div class="container">
            <div class="top-area">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 col-12">
                        <div class="product-images">
                            <main id="gallery">
                                <div class="main-img">
                                    <img src='https://dosttech.az/uploads/thumb__568fb-405925.gif' id="current" alt="#"/>
                                </div>
                                <div class="images">
      
                                </div>
                            </main>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-12">
                        <div class="product-info">
                            <h2 class="title">Xiomi telefon </h2>
                            <p class="category"><i class="lni lni-tag"></i> Kateqoriya:<a href="javascript:void(0)">Telefonlar</a></p>
                            <h3 class="price"> 200 AZN</h3>
                            <p class="info-text">Məlumat</p>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="form-group color-option">
                                        <label class="title-label" for="size">Rəng seç</label>
                                        <div class="single-checkbox checkbox-style-1">
                                            <input type="checkbox" id="checkbox-1" checked />
                                            <label for="checkbox-1"><span></span></label>
                                        </div>
                                        <div class="single-checkbox checkbox-style-2">
                                            <input type="checkbox" id="checkbox-2" />
                                            <label for="checkbox-2"><span></span></label>
                                        </div>
                                        <div class="single-checkbox checkbox-style-3">
                                            <input type="checkbox" id="checkbox-3"/>
                                            <label for="checkbox-3"><span></span></label>
                                        </div>
                                        <div class="single-checkbox checkbox-style-4">
                                            <input type="checkbox" id="checkbox-4"/>
                                            <label for="checkbox-4"><span></span></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="form-group quantity">
                                        <label for="color">Ədəd</label>
                                        <input type="number" className="form-control"  value={1}/>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom-content">
                                <div class="row align-items-end">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="button cart-button">
                                            <button class="btn">Səbətə əlavə et</button>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="wish-button">
                                            <button class="btn"><i class="lni lni-reload"></i> Qarşılaşdır</button>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="wish-button">
                                            <button class="btn"><i class="lni lni-heart"></i> Bəyən</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-details-info">
                <div class="single-block">
                    <div class="row">
                        <div class="col-lg-6 col-12">
                            <div class="info-body custom-responsive-margin">
                <div className="single-parametrs-details">
                    <div className="detail-key-value text_cap">
                      <div className="row">
                        <div className="col-6">
                          <span className="detail-key">Parametr:</span>
                        </div>
                        <div className="col-6">
                          <span className="detail-value">
                            Parametr data
                          </span>
                        </div>
                        <div className="col-6">
                          <span className="detail-key">Parametr:</span>
                        </div>
                        <div className="col-6">
                          <span className="detail-value">
                            Parametr data
                          </span>
                        </div>
                        <div className="col-6">
                          <span className="detail-key">Parametr:</span>
                        </div>
                        <div className="col-6">
                          <span className="detail-value">
                            Parametr data
                          </span>
                        </div>
                        <div className="col-6">
                          <span className="detail-key">Parametr:</span>
                        </div>
                        <div className="col-6">
                          <span className="detail-value">
                            Parametr data
                          </span>
                        </div>
                        <div className="col-6">
                          <span className="detail-key">Parametr:</span>
                        </div>
                        <div className="col-6">
                          <span className="detail-value">
                            Parametr data
                          </span>
                        </div>
                        <div className="col-6">
                          <span className="detail-key">Parametr:</span>
                        </div>
                        <div className="col-6">
                          <span className="detail-value">
                            Parametr data
                          </span>
                        </div>
                        <div className="col-6">
                          <span className="detail-key">Parametr:</span>
                        </div>
                        <div className="col-6">
                          <span className="detail-value">
                            Parametr data
                          </span>
                        </div>

                      </div>
                    </div>
                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="info-body">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>


  );
};

export default ProductDetail;
