import React, { createContext, useContext, useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "./Components/i18n";
import "./App.css";

import Layout from "./Components/Layout";
import Home from "./Pages/Home";
import ProductDetail from "./Pages/ProductDetail";
import ProductNew from "./Pages/ProductNew";

import Cart from "./Pages/Cart";
import Sign_in from "./Pages/Sign_in";
import Sign_up from "./Pages/Sign_up";

import Page from "./Pages/Page";
import Search from "./Pages/Search";
import Dashboard from "./Pages/Dashboard";
import axios from "axios";
import ProductComparison from "./Pages/Comparison";
import PaymentResult from "./Pages/protected/PaymentResult";
import SearchResult from "./Pages/SearchResult";
import CreditPayment from "./Pages/CreditPayment";
import Contact from "./Pages/Contact";
import MenuPage from "./Pages/MenuPage";
import CompleteOrder from "./Pages/CompleteOrder";
import ConfirmCart from "./Pages/ConfirmCart";
import Profile from "./Pages/Profile";
import MonthlyPayment from "./Components/MonthlyPayment";
import Business from "./Pages/Business";

const AuthContext = createContext({
  user: null,
  token: null,
  setUser: () => {},
  setToken: () => {},
});

function App() {
  const { i18n } = useTranslation();
  const url = window.location.pathname;
  const segments = url.split("/");

  useEffect(() => {
    const lang = segments[1];
    i18n.changeLanguage(lang || "az");
  }, []);


  return (
    <>
      <BrowserRouter>
        {/* <AuthProvider> */}
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path=":lang/" element={<Home />} />
              <Route path=":lang/product/:id" element={<ProductDetail />} />
              <Route path=":lang/productnew/" element={<ProductNew />} />

              <Route path=":lang/aylik-odenis/" element={<MonthlyPayment />} />
              <Route path=":lang/business/" element={<Business />} />

              <Route path=":lang/menu-page/:id" element={<MenuPage />} />
              <Route path="/payment-result" element={<PaymentResult />} />
              <Route path=":lang/searchResult/:storeSlug?&&:userCode?&&:initialPageIndex?&&:keyWord?&&:categoryId?&&:sortType?" element={<SearchResult />} />
              <Route path=":lang/credit-payment" element={<CreditPayment />} />
              <Route path=":lang/comparison" element={<ProductComparison />} />
              <Route path=":lang/contact" element={<Contact />} />
              <Route path=":lang/cart" element={<Cart />} />
              <Route path=":lang/cart/confirm-cart" element={<ConfirmCart />} />
              <Route path=":lang/cart/:id" element={<CompleteOrder />} />
              <Route path=":lang/sign_in" element={<Sign_in />} />
              <Route path=":lang/sign_up" element={<Sign_up />} />
              <Route path=":lang/profile" element={<Profile />} />
              {/* <Route element={<ProtectedRoute />}>
                <Route path=":lang/dashboard" element={<Dashboard />} />
              </Route> */}

              <Route path=":lang/Page" element={<Page />} />
              <Route path=":lang/Search" element={<Search />} />
            </Route>
          </Routes>
        {/* </AuthProvider> */}
      </BrowserRouter>
    </>
  );
}

export const useStateContext = () => useContext(AuthContext);

export default App;
