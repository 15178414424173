import React, { useEffect, useState } from "react";
import { useStateContext } from "../context/ContextProvider";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import axiosInstance from "../axiosInstance";

const ComparisonTable = (props) => {
  const [comparisonList, setcomparisonList] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const { productComparisons, removeProductFromComparison, lang } =
    useStateContext();
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [compareData, setcompareData] = useState([]);

  useEffect(() => {
    const fetchProductData = async () => {
      const productList = [];

      for (const slug of comparisonList) {
        const response = await axiosInstance.get(
          `api/Product/get-product?lang=${lang}&Slug=${slug}`
        );
        if (response.data.isSuccessful) {
          productList.push(response.data.data);
        }
      }

      setcompareData(productList);
    };

    fetchProductData();
  }, [comparisonList]);

  console.log(compareData);

  const homeProductDataFetch = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/Product/get-all-products?LanguageCode=${lang}&pageIndex=${page}`
      );

      const data = await response.json();
      if (data.data.items.length > 0) {
        setProducts((prev) => {
          const newProducts = [...prev, ...data.data.items];
          const uniqueProducts = Array.from(
            new Set(newProducts.map((product) => product.productId))
          ).map((productId) =>
            newProducts.find((product) => product.productId === productId)
          );
          sessionStorage.setItem("products", JSON.stringify(uniqueProducts));
          return uniqueProducts;
        });
        setHasMorePages(true);
      } else {
        setHasMorePages(false);
      }
    } catch (error) {
      setHasMorePages(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    homeProductDataFetch();
  }, [page]);

  useEffect(() => {
    if (productComparisons.length > 0) {
      setIsEmpty(false);
      setcomparisonList(productComparisons);
    } else {
      setIsEmpty(true);
      setcomparisonList([]);
    }
  }, [productComparisons]);

  const handleRemoveProduct = (slug) => {
    removeProductFromComparison(slug);
  };

  return (
    <div className="comparison-table container">
      {isEmpty ? (
        <h3 className="text-center my-4">Karşılaştırılacak ürün yok!</h3>
      ) : (
        <div className="row">
          {compareData.map((item) => {
            return (
              <div key={item.id} className="col-4 border p-3">
                <>
                  <div className="d-flex flex-column align-items-start justify-content-between mb-3" style={{height: "600px"}}>
                    <div className="w-100 d-flex align-items-center justify-content-between gap-3">
                      <h4 className="text-dark fw-light">
                        {item.productTitle}
                      </h4>
                      <button className="btn btn-outline-danger btn-sm rounded-circle">
                        <IoClose  onClick={() => handleRemoveProduct(item.slug)} />
                      </button>
                    </div>
                    <div className="w-100 d-flex flex-column align-items-start justify-content-between gap-3">
                      <img
                        className="img-fluid"
                        src={item.coverImage}
                        alt="ds"
                      />
                      <h3 className="fw-medium">{item.price} AZN</h3>
                      <button className="btn btn-primary btn-block w-100 my-3 rounded">
                        Sepete Ekle
                      </button>
                    </div>

                  </div>

                  <hr />

                  <div>
                    <ul className="m-0 p-0 ps-2 d-flex flex-column align-items-start gap-2">
                      {item.parameters &&
                        item.parameters.map((parameter, index) => (
                          <li
                            key={index}
                            className="d-flex flex-column align-items-start gap-1"
                          >
                            <small>{parameter.parameterTitle}</small>
                            <label className="fw-bold">
                              {parameter.parameterValue}
                            </label>
                          </li>
                        ))}
                    </ul>
                  </div>
                </>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const CustomAttributeLabel = (props) => {
  return <span style={{ textTransform: "capitalize" }}>{props.children}</span>;
};

const CustomColorLabel = (props) => {
  return (
    <div
      style={{
        backgroundColor: props.backgroundColor,
        width: "20px",
        height: "20px",
        margin: "auto",
      }}
    ></div>
  );
};

const CustomPriceLabel = (props) => {
  return <span style={{ color: "green" }}>{props.children}</span>;
};

const renderCellValue = (row, filter) => {
  switch (filter) {
    case "price":
      return <CustomPriceLabel>{row[filter]}</CustomPriceLabel>;
    case "colors":
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {row[filter].map((color, idx) => (
            <CustomColorLabel key={idx} backgroundColor={color} />
          ))}
        </div>
      );
    default:
      return row[filter] instanceof Array
        ? renderArrayValue(row[filter])
        : row[filter];
  }
};

const renderArrayValue = (arr) => {
  return (
    <ul className="list-unstyled">
      {arr.map((item, idx) => (
        <li key={idx}>{item}</li>
      ))}
    </ul>
  );
};

export default ComparisonTable;
