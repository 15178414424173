import React from "react";

const Business = () => {
  return (
    <div className="container py-5" style={{ minHeight: "100vh" }}>
      <div className="row">
        <div className="col">
          <h1>Korporativ Satış</h1>
          <p>
            Biz “DostTech” Şirkəti olaraq sizə ən sərfəli qiymətlərlə istənilən
            elektronika, məişət və mətbəxt əşyalarının Korporativ satışını
            təklif edirik.
          </p>
          <h5 className="mt-4">Korporativ təkliflərimizə daxildir:</h5>
          <ol className="list-group list-group-numbered mb-4">
            <li className="list-group-item border-0 py-1">Endirimli qiymət</li>
            <li className="list-group-item border-0 py-1">Köçürmə yolu ilə</li>
            <li className="list-group-item border-0 py-1">ƏDV daxil</li>
            <li className="list-group-item border-0 py-1">
              Əlavə olaraq istəsəz Endirimli qiymət zamanı yaranacaq endirim
              məbləğinin dəyərində mağazamızdan hansısa bir məhsulu hədiyyə kimi
              əldə edə bilərsiniz. (bu zaman korporativ satış endirimli qiymətə
              olmayacaq)
            </li>
          </ol>
          <div className="d-flex flex-column gap-1">
            <h6 className="d-flex align-items-center gap-1">
              <span>Əlaqə</span>
              <a href="tel:1221">*1221</a>
            </h6>
            <a href="mailto:info@dosttech.az">info@dosttech.az</a>
            <a href="www.dosttech.az">www.dosttech.az</a>
            <p className="fw-semibold">Hörmətlə: DostTech</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Business;
