import React, { useEffect, useState } from "react";
import { useStateContext } from "../context/ContextProvider";
import { Link } from "react-router-dom";
import "../styles/Comparison.css";
import axiosInstance from "../axiosInstance";

const ComparisonBar = () => {
  const { productComparisons, clearProductComparison, lang } =
    useStateContext();
  const [isBarOpen, setIsBarOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [compareList, setCompareList] = useState([]);

  useEffect(() => {
    const fetchProductData = async () => {
      const productList = [];

      for (const slug of productComparisons) {
        const response = await axiosInstance.get(
          `api/Product/get-product?lang=${lang}&Slug=${slug}`
        );
        if (response.data.isSuccessful) {
          productList.push({
            id: response.data.data.productId,
            cover_image: response.data.data.coverImage,
            product_title: response.data.data.productTitle,
            slug: response.data.data.slug,
          });
        }
      }

      setCompareList(productList);
    };

    fetchProductData();
  }, [productComparisons]);

  const handleRemove = () => {
    clearProductComparison();
  };

  const handleBarToggle = () => {
    setIsBarOpen(!isBarOpen);
  };

  return (
    <>
      {productComparisons.length > 0 ? (
        <div
          className={`comparison position-sticky bottom-0 start-0 bg-light shadow-lg w-100 py-3 ${
            isBarOpen ? "comparison-bar-open" : ""
          }`}
          style={{ zIndex: 99 }}
        >
          <div className="container position-relative d-flex flex-sm-row flex-column align-items-center justify-content-between gap-2">
            <button
              onClick={() => handleBarToggle()}
              className="selected-products bg-primary text-white fw-bold"
            >
              Seçili Ürünler ({productComparisons.length}/3)
            </button>
            <ul className="d-flex align-items-center justify-content-start gap-3 mb-0 p-0">
              {compareList.length > 0 ? (
                compareList.map((item, index) => (
                  <a
                    href={`/az/product/${item.slug}`}
                    key={index}
                    className="d-flex flex-column align-items-start gap-1 text-dark text-decoration-none"
                    style={{ fontSize: "10px" }}
                  >
                    <img
                      src={item.cover_image}
                      alt=""
                      style={{ width: "30px", height: "30px" }}
                    />
                    {item.product_title}
                  </a>
                ))
              ) : (
                <></>
              )}
            </ul>
            <div className="d-flex align-items-center justify-content-center gap-3">
              <Link
                className="btn btn-primary text-white btn-sm"
                to={"az/comparison"}
              >
                Karşılaştır
              </Link>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => handleRemove()}
              >
                x
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ComparisonBar;
