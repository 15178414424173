import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import ComparisonBar from "./ComparisonBar";
import { useStateContext } from "../context/ContextProvider";
const Layout = ({ lang }) => {
  const [getProducts, setGetProducts] = useState([]);
  const { basket } = useStateContext();

  const basketData = Object.entries(basket || []);

  const formattedData = basketData.map(([productId, productCount]) => ({
    productSlug: productId,
    productCount: parseInt(productCount),
  }));

  useEffect(() => {
    getProduct();
  }, [basket]);

  const getProduct = async () => {
    try {
      const productsArr = [];

      for (const item of formattedData) {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/Product/get-product?lang=az&Slug=${item.productSlug}`
        );
        const data = await response.json();

        productsArr.push(data.data);
      }

      setGetProducts(productsArr);
    } catch (error) {
      console.error("Error get product data:", error);
    }
  };

  return (
    <>
      <Header cartData={getProducts} />
      <Outlet />
      <ComparisonBar />
      <Footer />
    </>
  );
};

export default Layout;
