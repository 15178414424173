import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../Lang/en.json';
import az from '../Lang/az.json';
import ru from '../Lang/ru.json';
i18n
  .use(initReactI18next)
  .init({
    resources: {
      az: {
            translation: az,
          },

      en: {
        translation: en,
      },

      ru: {
        translation: ru,
      },
    },
    fallbackLng: 'az',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;