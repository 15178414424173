import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axiosInstance from "../axiosInstance";
import { useStateContext } from "../context/ContextProvider";
import ProductCard from "../Components/ProductCard";
import LoadingAnimation from "../Components/LoadingAnimation";
import "../styles/SearchResult.css";
import Filter from "../Components/Filter";
import { MdKeyboardArrowUp } from "react-icons/md";
import { IoChevronDownOutline, IoChevronUp } from "react-icons/io5";

const SearchResult = () => {
  const { lang } = useStateContext();
  const [searchParams] = useSearchParams();
  const keyWord = searchParams.get("keyWord");
  const storeSlug = searchParams.get("storeSlug");
  const userCode = searchParams.get("userCode");
  const initialPageIndex = searchParams.get("initialPageIndex");
  const categoryId = searchParams.get("categoryId");
  const sortType = searchParams.get("SortType");
  const [pageIndex, setPageIndex] = useState(parseInt(initialPageIndex));
  const [searchResults, setSearchResults] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [filters, setFilters] = useState({
    keyWord,
    storeSlug,
    userCode,
    initialPageIndex,
    categoryId,
    sortType,
  });
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const prevFilters = useRef(filters);
  const prevSortType = useRef(sortType);
  const debounceTimeoutRef = useRef(null);
  const [category, setCategory] = useState({});
  const [getParams, setGetParams] = useState({});
  const [paramValues, setParamValues] = useState([]);

  let { state } = useLocation();

  const getParametersData = async (C_id) => {
    try {
      let fetchUrl = `${process.env.REACT_APP_BASE_URL}/api/Parameter/get-parameters?lang=az&RequestFrontType=search`;
      if (C_id) {
        fetchUrl += `&CategoryId=${C_id}`;
      } else if (categoryId) {
        fetchUrl += `&CategoryId=${categoryId}`;
      }
      const response = await fetch(fetchUrl);
      const data = await response.json();
      const parameters = data.data.filter(
        (param) => param.parameterTypeId !== 2
      );
      setParamValues(parameters);
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };

  const handleParameterChange = (e) => {
    e.preventDefault();
    setSearchResults([]);
    const { name, value } = e.target;
    if (value === "") {
      const { [name]: deletedValue, ...updatedParams } = getParams;
      setGetParams(updatedParams);
    } else {
      setGetParams({
        ...getParams,
        [name]: value,
      });
    }
  };

  const fetchData = async (
    pageIndex,
    keyword,
    categoryId,
    sortType,
    params
  ) => {
    setLoading(true);
    if (prevFilters.current !== keyword || prevSortType.current !== sortType) {
      setSearchResults([]);
      setPageIndex(0);
    }

    const urlParams = new URLSearchParams({
      lang: "az",
      categoryId: categoryId ? categoryId : "",
      keyWord: keyword ? keyword : "",
      sortType: sortType ? sortType : "orderbydescdate",
      pageIndex: pageIndex ? pageIndex : 0,
      ...params,
    });

    const response = await axiosInstance.get(
      `${
        process.env.REACT_APP_BASE_URL
      }/api/Product/search?lang=${lang}&StoreSlug=${
        storeSlug ? storeSlug : ""
      }&UserCode=${userCode ? userCode : ""}&${urlParams.toString()}`
    );

    window.history.pushState({}, "", `?${urlParams}`);

    if (response.data.data.items.length > 0) {
      if (pageIndex === 0) {
        setSearchResults(response.data.data.items);
      } else {
        setSearchResults((prevResults) => [
          ...prevResults,
          ...response.data.data.items,
        ]);
      }
      setHasMore(true);
      setTotalCount(response.data.data.totalCount);
    } else {
      setHasMore(false);
    }

    prevFilters.current = keyword;
    prevSortType.current = sortType;

    setLoading(false);
  };
  useEffect(() => {
    fetchData(0, keyWord, categoryId, sortType);
    setPageIndex(0);
  }, [keyWord, sortType, categoryId]);

  const handleScroll = () => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      const scrollTop =
        (document.documentElement && document.documentElement.scrollTop) ||
        document.body.scrollTop;
      const scrollHeight =
        (document.documentElement && document.documentElement.scrollHeight) ||
        document.body.scrollHeight;

      if (scrollHeight - scrollTop - window.innerHeight < 50) {
        setPageIndex((prevPageIndex) => prevPageIndex + 1);
        console.log("Page Index: " + pageIndex);
      }
    }, 200);
  };

  useEffect(() => {
    if (keyWord !== undefined && categoryId !== undefined) {
      console.log("Filters: ", filters);
      fetchData(pageIndex, keyWord, categoryId, sortType, getParams);
      window.addEventListener("scroll", handleScroll);
      return () => {
        if (debounceTimeoutRef.current) {
          clearTimeout(debounceTimeoutRef.current);
        }
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [pageIndex, keyWord, categoryId]);

  const f_categories = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/Category/get-all-categories?lang=az`
      );
      const data = await response.json();
      setCategories(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSubCategories = async (categoryId, params) => {
    setSubCategories([]);
    try {
      const baseUrl = `${process.env.REACT_APP_BASE_URL}/api/Parameter/get-all-categories-search`;
      const urlParams = new URLSearchParams({
        lang: "az",
        CategoryId: categoryId,
        ...params,
      });

      const url = `${baseUrl}?${urlParams.toString()}`;
      const response = await fetch(url);
      const data = await response.json();

      if (data.isSuccessful) {
        getParametersData(categoryId);
        setSubCategories(data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSubCategoriesWithKeyword = async (keyword) => {
    setSubCategories([]);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/Parameter/get-all-categories-search?lang=az&KeyWord=${keyword}`
      );
      const data = await response.json();

      if (data.isSuccessful) {
        setSubCategories(data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (keyWord && !categoryId) {
      fetchSubCategoriesWithKeyword(keyWord);
    }
  }, [keyWord, categoryId]);

  useEffect(() => {
    f_categories();
    fetchSubCategories(categoryId, getParams);
    fetchData(0, keyWord, categoryId, sortType, getParams);
  }, [categoryId, getParams]);

  useEffect(() => {
    if (state) {
      setCategory(
        state.subCategories.find(
          (item) => item.categoryId === parseInt(categoryId)
        )
      );
    }
  }, [state]);

  const renderParameterInputs = () => {
    return paramValues.map((param, index) => {
      let input;
      if (param.parameterMasks !== null && param.parameterMasks.length > 0) {
        const options = param.parameterMasks.map((mask) => {
          const isSelectedOpt =
            mask.parameterMaskData === getParams[param.parameterKey];
          return (
            <option
              key={mask.parameterMaskId}
              value={mask.parameterMaskData}
              data-key={mask.parameterMaskId}
              selected={isSelectedOpt ? "selected" : null}
            >
              {mask.parameterMaskData}
            </option>
          );
        });
        input = (
          <select
            className="form-select mb-2"
            name={param.parameterKey}
            key={param.parameterId}
            onChange={handleParameterChange}
          >
            <option value=""> {param.parameterTitle} seçin</option>
            {options}
          </select>
        );
      } else {
        const inputType =
          param.parameterTypeTitle === "integer" ? "number" : "text";
        input = (
          <>
            <label className="fw-semibold">{param.parameterTitle}</label>
            <input
              className="form-control mb-2"
              type={inputType}
              name={param.parameterKey}
              key={param.parameterId}
              onChange={handleParameterChange}
              value={getParams[param.parameterKey]}
            />
          </>
        );
      }
      return (
        <React.Fragment key={index}>
          <div className="single-widget filter-item">{input}</div>
        </React.Fragment>
      );
    });
  };

  return (
    <div class="container">
      <div className="my-4">
        <div className="">
          <Filter
            filters={filters}
            setFilters={setFilters}
            fetchData={fetchData}
            sortType={sortType}
            categories={categories}
            totalCount={totalCount}
            category={category}
            subCategories={subCategories}
            state={state}
            keyWord={keyWord}
            renderParameterInputs={renderParameterInputs()}
            params={getParams}
          />
        </div>
      </div>
      <div>
        <LoadingAnimation active={loading} />
      </div>
      <div className="row d-flex justify-content-center">
        <div>
          <div className="row">
            <div className="col-md-3 col-12 d-md-block d-none product-sidebar">
              <div
                className="single-widget filter-item"
                style={{ marginTop: "30px" }}
              >
                <label htmlFor="" className="fw-semibold">
                  Kategoriler
                </label>
                <>
                  <ul className="ps-2">
                    {subCategories &&
                      (state ? (
                        <li>{category.categoryTitle}</li>
                      ) : (
                        subCategories.map((item, index) => (
                          <li key={index}>
                            <Link
                              className="btn text-start px-0"
                              style={{ fontSize: "12px" }}
                              to={
                                keyWord
                                  ? `/az/searchResult?categoryId=${item.categoryId}&keyWord=${keyWord}&SortType=orderbydescdate`
                                  : `/az/searchResult?categoryId=${item.categoryId}&SortType=orderbydescdate`
                              }
                              state={{
                                subCategories: subCategories,
                                keyWord: keyWord,
                              }}
                            >
                              {item.categoryTitle} ({item.count})
                            </Link>
                          </li>
                        ))
                      ))}
                  </ul>
                </>
              </div>
              {/* <div
                className="single-widget filter-item"
                style={{ marginTop: "30px" }}
              >
                <label htmlFor="price" className="fw-semibold">
                  Qıymet
                </label>
                <input
                  type="number"
                  id="price"
                  name="price"
                  onChange={handleParameterChange}
                  value={getParams["price"]}
                />
              </div> */}
              {renderParameterInputs()}
            </div>
            {searchResults.length === 0 && !loading ? (
              <div className="col-md-9 col-12 no-results">
                Aranan kriterlere uygun urun bulunamadi
              </div>
            ) : (
              <div className="col-md-9 col-12 ">
                <ProductCard products={searchResults} />
              </div>
            )}
          </div>
        </div>
      </div>
      <button className="scroll-to-top" onClick={() => window.scrollTo(0, 0)}>
        <IoChevronUp />
      </button>
    </div>
  );
};

export default SearchResult;
