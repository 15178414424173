import React, { useEffect, useState } from "react";
import { useStateContext } from "../context/ContextProvider";
import axiosInstance from "../axiosInstance";
import "../styles/Filter.css";
import { IoFilter } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";

const Filter = ({
  filters,
  setFilters,
  fetchData,
  sortType,
  categories,
  totalCount,
  category,
  subCategories,
  state,
  keyWord,
  renderParameterInputs,
  params,
}) => {
  const [sortTypes, setSortTypes] = useState([]);
  const { lang } = useStateContext();
  const [isMobileFilterVisible, setMobileFilterVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(`api/sort/get-all-sort-types?lang=${lang}`)
      .then((response) => {
        if (response.data.isSuccessful) {
          setSortTypes(response.data.data);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  // console.log(filters);

  const handleSelectChange = (event) => {
    const selectedSortKey = event.target.value;
    fetchData(0, filters.keyWord, filters.categoryId, selectedSortKey, params);
    return navigate(
      `/az/searchResult?categoryId=${filters.categoryId}&SortType=${selectedSortKey}`
    );
    // console.log(selectedSortKey);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };
  console.log(renderParameterInputs);
  return (
    <>
      {totalCount > 0 && (
        <div className="d-md-none d-flex filter border-0 px-0">
          İlgili {totalCount} ürün bulduk
        </div>
      )}
      <div className="filter">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="d-md-flex d-none">
            {totalCount > 0 && (
              <div className="filter border-0 px-0">
                İlgili {totalCount} ürün bulduk
              </div>
            )}
          </div>
          <div className="d-md-none d-flex align-items-center gap-2">
            <div className="filter-item">
              <label>Filtreler</label>
              <button
                className="btn border border-secondary text-dark"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
              >
                <IoFilter />
              </button>

              <div
                class="offcanvas offcanvas-start"
                tabindex="-1"
                id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div class="offcanvas-header">
                  <h5 class="offcanvas-title" id="offcanvasExampleLabel">
                    Filtreler
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="offcanvas-body">
                  {/* Add more filters as needed */}
                  <div className="filter-item">
                    <label className="fw-semibold" htmlFor="">
                      Kategoriler
                    </label>
                    <div className="d-flex align-items-center justify-content-start flex-wrap gap-2">
                      <ul className="ps-2">
                        {subCategories &&
                          (state ? (
                            <li>
                              {category.categoryTitle} ({category.count})
                            </li>
                          ) : (
                            subCategories.map((item, index) => (
                              <li key={index}>
                                <Link
                                  className="btn text-start px-0"
                                  style={{ fontSize: "12px" }}
                                  to={
                                    keyWord
                                      ? `/az/searchResult?categoryId=${item.categoryId}&keyWord=${keyWord}&SortType=orderbydescdate`
                                      : `/az/searchResult?categoryId=${item.categoryId}&SortType=orderbydescdate`
                                  }
                                  state={{
                                    subCategories: subCategories,
                                    keyWord: keyWord,
                                  }}
                                >
                                  <span data-bs-dismiss="offcanvas">
                                    {item.categoryTitle} ({item.count})
                                  </span>
                                </Link>
                              </li>
                            ))
                          ))}
                      </ul>
                    </div>
                  </div>

                  {renderParameterInputs}
                </div>
              </div>
            </div>
          </div>

          <div className="filter-item">
            <label htmlFor="sortType">Çeşidləmə Meyarları</label>
            <select
              id="sortType"
              className="filter-select"
              value={sortType}
              onChange={handleSelectChange}
            >
              {sortTypes.map((sortType) => (
                <option key={sortType.sort_type_id} value={sortType.sortKey}>
                  {sortType.sortTypeTitle}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* <div className="filter mt-3">
        <div className="filter-item">
          <div className="filter-categories w-100 d-md-flex d-none align-items-center justify-content-start overflow-x-auto gap-2 py-2">
            {categories &&
              categories.map((item, index) => (
                <a
                  href={`/az/searchResult?categoryId=${item.categoryId}&SortType=orderbydescdate`}
                  className="badge rounded-pill text-white position-relative text-decoration-none"
                  style={{ background: "#0b2875" }}
                  key={index}
                >
                  {item.categoryTitle}{" "}
                </a>
              ))}
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Filter;
