import React, { useState, useEffect } from "react";
import {
  IoCashOutline,
  IoChatbubbleEllipsesOutline,
  IoHome,
  IoHeart,
  IoPerson,
  IoCartOutline,
  IoHeartOutline,
  IoCallOutline,
  IoSearch,
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoYoutube,
  IoLogoWhatsapp,
  IoLogoTiktok,
  IoLogoLinkedin,
  IoLogoTwitter,
  IoNavigateCircle,
  IoApps,
  IoClose,
  IoCaretDownOutline,
  IoCaretForward,
  IoMenu,
  IoShareSocialSharp,
  IoInformationCircleOutline,
  IoWalletOutline,
  IoSwapHorizontal,
  IoPersonOutline,
  IoMailSharp,
  IoMailOpenOutline,
  IoCall 
} from "react-icons/io5";
import "../styles/Footer.css";

const Footer = () => {
  const FooterLogoUrl = process.env.PUBLIC_URL + "/images/logo.webp";
  const [menuData, setMenuData] = useState({});

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_ADMIN_URL}/api/Menu/get-all-menus-by-type?menuTypeId=3`
    )
      .then((response) => response.json())
      .then((data) => setMenuData(data.data));
  }, []);

  return (
    <>
      {/* Footer */}

      <footer className="footer_n pt-4 pb-3">
        <div className="footer-bottom_n">
          <div className="container">
            <div className="inner-content">
              <div className="row align-items-center">
                <div className="col-lg-4 col-12">
                  <h4 className="" style={{ color: "#FFD700" }}>
                    Əlaqə
                  </h4>
                  <div className="d-flex flex-column align-items-start gap-2 mb-3">
                    <a
                      className="text-light mt-2 d-flex align-items-center gap-1"
                      href="tel:1221"
                      style={{ textDecoration: "none" }}
                    >
                      <IoCallOutline className="fs-3" />
                      <h4>*1221</h4>
                    </a>
                    <a href="#" style={{ color: "#FFD700", fontSize: "14px" }}>
                      Zeng Sifariş Et
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-12 d-flex flex-column align-items-md-center justify-content-start gap-3 mb-3">
                  <a
                    className="d-flex align-items-center gap-2 text-light"
                    href="mailto:info@dosttech.az"
                    style={{ textDecoration: "none" }}
                  >
                    <IoMailOpenOutline />
                    <span>info@dosttech.az</span>
                  </a>
                  <a
                    className="d-flex align-items-center gap-2 text-light"
                    href="tel:+994772551221"
                    style={{ textDecoration: "none" }}
                  >
                    <IoCall />
                    <span>+994772551221</span>
                  </a>
                </div>
                <div className="col-lg-4 col-12 text-md-end text-start">
                  <a
                    href="https://fraktal.az/"
                    rel="nofollow"
                    target="_blank"
                    className="fraktal-link"
                  >
                    fraktal
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="footer-cards_n">
        <img
          className="footer-card_n"
          src="https://static.dosttech.az/2/card_types/worldkart.png"
          alt="Card 1"
        />
        <img
          className="footer-card_n"
          src="https://static.dosttech.az/2/card_types/visa.png"
          alt="Card 2"
        />
        <img
          className="footer-card_n"
          src="https://static.dosttech.az/2/card_types/tamkart.png"
          alt="Card 3"
        />
        <img
          className="footer-card_n"
          src="https://static.dosttech.az/2/card_types/mastercard.gif"
          alt="Card 4"
        />
        <img
          className="footer-card_n"
          src="https://static.dosttech.az/2/card_types/jcb_logo.png"
          alt="Card 5"
        />
        <img
          className="footer-card_n"
          src="https://static.dosttech.az/2/card_types/bolkart.png"
          alt="Card 6"
        />
        <img
          className="footer-card_n"
          src="https://static.dosttech.az/2/card_types/birkart.png"
          alt="Card 7"
        />
        <img
          className="footer-card_n"
          src="https://static.dosttech.az/2/card_types/albali.jpg"
          alt="Card 8"
        />
        <img
          className="footer-card_n"
          src="https://static.dosttech.az/2/card_types/worldkart.png"
          alt="Card 9"
        />
      </div>
      {/* End Footer */}
    </>
  );
};

export default Footer;
