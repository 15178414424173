import React from "react";
import { IoCall, IoMailOpenOutline } from "react-icons/io5";

const Contact = () => {
  return (
    <div className="container my-5" style={{ minHeight: "100vh" }}>
      <h1 className="text-center mb-4">İletişim</h1>
      <div className="row">
        <div className="col-md-6 col-12">
          <h4>Mağazalar</h4>
          <ul style={{ listStyleType: "circle" }}>
            <li>Mərdəkan qəs. Sergey Yesenin küç. 106</li>
            <li>Buzovna qəs. Mustafa Sübhi küç 2</li>
          </ul>

          <h4 className="mt-4">Əlaqə</h4>
          <div className="d-flex flex-column align-items-start gap-2 mb-3">
            <a
              className="mt-2 d-flex align-items-center gap-1 text-dark"
              href="tel:1221"
            >
              <h5>* 1221</h5>
            </a>
            <a
              className="d-flex align-items-center gap-2 text-dark"
              href="tel:077 255-12-21"
              style={{ textDecoration: "none" }}
            >
              <IoCall />
              <span>077 255-12-21</span>
            </a>
            <a
              className="d-flex align-items-center gap-2 text-dark"
              href="mailto:info@dosttech.az"
              style={{ textDecoration: "none" }}
            >
              <IoMailOpenOutline />
              <span>info@dosttech.az</span>
            </a>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <h2>Harita</h2>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              title="Konum"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d97236.6351751089!2d49.77255940737916!3d40.394563255933285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d6bd6211cf9%3A0x343f6b5e7ae56c6b!2sBaku%2C%20Azerbaijan!5e0!3m2!1sen!2str!4v1714341107475!5m2!1sen!2str"
              style={{ width: "100%", height: "420px" }}
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
