import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('ACCESS_TOKEN');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status === 401) {
      const originalRequest = error.config;
      const refreshToken = localStorage.getItem('REFRESH_TOKEN');
      if (refreshToken) {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/auth/get-token-by-refresh-token`,
            {
              headers: {
                "access-control-allow-origin": "*",
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'refresh_token': localStorage.getItem("REFRESH_TOKEN")
              },
            }
          );

          localStorage.setItem("ACCESS_TOKEN", data.data.token);
          localStorage.setItem("user", JSON.stringify(data.data.userModel));
          localStorage.setItem("REFRESH_TOKEN", data.data.refreshToken);
          originalRequest.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

          return axiosInstance(originalRequest);
        } catch (refreshError) {
          cleanupLocalStorage();
          return Promise.reject(refreshError);
        }
      } else {
        cleanupLocalStorage();
      }
    }
    return Promise.reject(error);
  }
);

function cleanupLocalStorage() {
  window.location.href = '/az/login';
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('refreshToken');
}

export default axiosInstance;
