import React, { useEffect, useState } from "react";
import axios from "axios";
import ProductCard from "../Components/ProductCard";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  IoEye,
  IoEyeOff,
  IoPersonCircle,
  IoMail,
  IoCall,
} from "react-icons/io5";
import { useStateContext } from "../App";

const Sign_up = () => {
  const { setToken, setUser, token } = useStateContext();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const [formData, setFormData] = useState({
    userFirstName: "",
    userLastName: "",
    userPhone: "",
    userEmail: "",
    userPassword: "",
    confirmPassword: "",
  });

  
  if (token) {
    return <Navigate to="/" />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/register-user`,
        formData
      );
      const data = response.data;
      if (data.statusCode === 201) {
        if (data.isSuccessful === true) {
          window.location.href = "/actioninfo/regsuccess";
        } else {
          toast.error(data.messages[0]);
        }
      } else {
        toast.error(data.messages[0]);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.messages
      ) {
        toast.error(error.response.data.messages[0]);
      } else {
        toast.error("Gözlənilməyən xəta baş verdi.");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleRePasswordVisibility = () => {
    setShowRePassword(!showRePassword);
  };

  return (
    <>
      <div class="shopping-cart py-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-12"></div>
            <div class="col-lg-4 col-12">
              <div class="card">
                <div class="card-body">
                  <form onSubmit={handleSubmit} className="col-12">
                    <label for="userFirstName" className="form-label">
                      Ad*
                    </label>

                    <div class="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        name="userFirstName"
                        required
                        id="userFirstName"
                        onChange={handleInputChange}
                      />
                      <span class="input-group-text" id="basic-addon2">
                        <IoPersonCircle />
                      </span>
                    </div>

                    <label for="userLastName" className="login-labels">
                      Soyad*
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        name="userLastName"
                        required
                        id="userLastName"
                        onChange={handleInputChange}
                      />
                      <span class="input-group-text" id="basic-addon2">
                        <IoPersonCircle />
                      </span>
                    </div>

                    <label for="userEmail" className="login-labels">
                      Elektron-poçt*
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="email"
                        className="form-control"
                        name="userEmail"
                        required
                        id="userEmail"
                        onChange={handleInputChange}
                      />
                      <span class="input-group-text" id="basic-addon2">
                        <IoMail />
                      </span>
                    </div>

                    <label for="userPhone" className="login-labels">
                      Əlaqə nömrəsi *
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="tel"
                        className="form-control"
                        id="userPhone"
                        required
                        name="userPhone"
                        onChange={handleInputChange}
                      />
                      <span class="input-group-text" id="basic-addon2">
                        <IoCall />
                      </span>
                    </div>

                    <label for="userPassword" className="login-labels">
                      Şifrə*
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="userPassword"
                        name="userPassword"
                        onChange={handleInputChange}
                      />
                      <span class="input-group-text" id="basic-addon2">
                        {showPassword ? (
                          <IoEyeOff onClick={togglePasswordVisibility} />
                        ) : (
                          <IoEye onClick={togglePasswordVisibility} />
                        )}
                      </span>
                    </div>
                    <label for="confirmPassword" className="login-labels">
                      Şifrənin təkrarı*
                    </label>

                    <div class="input-group mb-3">
                      <input
                        type={showRePassword ? "text" : "password"}
                        required
                        className="form-control"
                        id="confirmPassword"
                        name="confirmPassword"
                        onChange={handleInputChange}
                      />
                      <span class="input-group-text" id="basic-addon2">
                        {showRePassword ? (
                          <IoEyeOff onClick={toggleRePasswordVisibility} />
                        ) : (
                          <IoEye onClick={toggleRePasswordVisibility} />
                        )}
                      </span>
                    </div>

                    <div className="d-flex justify-content-center align-items-center  apply-info mb-3">
                      <input type="radio"></input>
                      <div>
                        <span className="text-secondary">Razıyam</span>
                        <Link to="">Terms of Use</Link>{" "}
                        <span className="text-secondary">&</span>
                        <Link to=""> Privacy Policy</Link>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button className="btn btn-success" type="submit">
                        Qeydiyyat ol
                      </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center  apply-info  mb-3">
                      <div className="mt-3">
                        <span className="text-secondary">
                          Artıq hesabınız mövcuddur?
                        </span>
                        <Link to="/az/sign_in"> Daxil ol</Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sign_up;
