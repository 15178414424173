import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../context/ContextProvider";
import { IoClose } from "react-icons/io5";
import LoadingAnimation from "../Components/LoadingAnimation";

const Cart = () => {
  const [getProducts, setGetProducts] = useState([]);
  const [confirmCart, setConfirmCart] = useState(true);
  const [confirmCartMessage, setConfirmCartMessage] = useState("");
  const [isBasketEmpty, setIsBasketEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { basket, setBasket } = useStateContext();
  const { lang, token } = useStateContext();
  const navigate = useNavigate();

  const basketData = Object.entries(basket || []);

  let totPrice = 0;

  let formattedData = basketData.map(([productId, productCount]) => ({
    productSlug: productId,
    productCount: parseInt(productCount),
  }));

  useEffect(() => {
    getProduct();
  }, [basket]);

  const getProduct = async () => {
    try {
      const pros = [];

      for (const item of formattedData) {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/Product/get-product?LanguageCode=${lang}&Slug=${item.productSlug}`
        );
        const data = await response.json();

        pros.push(data.data);
      }

      setGetProducts(pros);
    } catch (error) {
      console.error("Error get product data:", error);
    }
  };

  const removeFromBasket = async (slug) => {
    setIsLoading(true);
    console.log("Loading: ", isLoading);

    if (token) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/Basket/remove-product?ProductSlug=${slug}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();

        if (data.isSuccessful) {
          delete basket[slug];
          localStorage.setItem("basket", JSON.stringify(basket));
          setBasket(basket);
          setGetProducts((prevProducts) =>
            prevProducts.filter((product) => product.slug !== slug)
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
        console.log("Loading: ", isLoading);
      }
    } else {
      const basket = JSON.parse(localStorage.getItem("basket")) || {};

      if (basket.hasOwnProperty(slug)) {
        delete basket[slug];
        localStorage.setItem("basket", JSON.stringify(basket));
        setBasket(basket);
      } else {
        alert("Bu məhsul sepetdə yoxdur");
      }
    }
  };

  const changeProductQuantity = (newQuantity, slug) => {
    const basket = JSON.parse(localStorage.getItem("basket")) || {};

    basket[slug] = newQuantity;

    localStorage.setItem("basket", JSON.stringify(basket));
    setBasket(basket);
  };

  const handleBasket = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/Basket`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formattedData),
        }
      );

      const data = await response.json();

      console.log(data);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    if (token) {
      handleBasket();
    }
  }, [token, basket]);

  const checkBasket = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/Basket/get-basket?lang=${lang}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const result = await response.json();

    if (result.isSuccessful) {
      setIsBasketEmpty(false);
      setConfirmCart(true);
    } else {
      setIsBasketEmpty(true);
      setConfirmCart(false);
      setConfirmCartMessage(
        "Devam edebilmek için sepette ürün olması gerekiyor!"
      );
    }
  };

  useEffect(() => {
    if (token) {
      checkBasket();
    }
  }, [token, basket]);

  useEffect(() => {
    if (token) {
      if (isBasketEmpty && basket.length === 0) {
        console.log("Sepet boş");
      } else {
        handleBasket();
      }
    }
  }, [basket, token, isBasketEmpty]);

  const handleConfirmCart = () => {
    if (!token) {
      setConfirmCart(false);
      setConfirmCartMessage("Devam edebilmek için giriş yapmalısınız!");
    } else if (!confirmCart) {
      return;
    } else {
      setConfirmCart(true);
      navigate("/az/cart/confirm-cart", { state: basket });
    }
  };

  return (
    <>
      <div className="shopping-cart py-5" style={{ minHeight: "100vh" }}>
        <div className="container">
          <div className="row">
            <div>
              <LoadingAnimation active={isLoading} />
            </div>
            <div className="col-lg-8 col-12">
              <div className="cart-list-head">
                <div className="cart-list-title d-md-block d-none">
                  <div className="row">
                    <div className="col-lg-1 col-md-1 col-12"></div>
                    <div className="col-lg-4 col-md-3 col-12">
                      <p>Məhsul</p>
                    </div>
                    <div className="col-lg-2 col-md-2 col-12">
                      <p>Ədəd</p>
                    </div>
                    <div className="col-lg-2 col-md-2 col-12">
                      <p>Toplam</p>
                    </div>
                    <div className="col-lg-1 col-md-2 col-12">
                      <p>Əməliyyat</p>
                    </div>
                  </div>
                </div>

                {getProducts.length > 0 ? (
                  getProducts.map((item, index) => {
                    const filteredItem = formattedData.find(
                      (filtered) => filtered?.productSlug === item?.slug
                    );
                    const value = filteredItem?.productCount;
                    totPrice += item?.price * parseInt(value);
                    return (
                      <div className="cart-single-list" key={index}>
                        <div className="row align-items-center">
                          <div className="col-lg-1 col-md-1 col-12">
                            <a href={`/az/product/${item.slug}`}>
                              <img src={item.coverImage} alt="#" />
                            </a>
                          </div>
                          <div className="col-lg-4 col-md-3 col-12">
                            <h5 className="product-name">
                              <a href={`/az/product/${item.slug}`}>
                                {item.productTitle}
                              </a>
                            </h5>
                            <p className="product-des">
                              <span>
                                <em>Növ:</em> {item.categoryTitle}
                              </span>
                              <span>
                                <em>Rəng:</em> Qara
                              </span>
                            </p>
                          </div>
                          <div className="col-lg-2 col-md-2 col-12">
                            <div className="count-input">
                              <select
                                className="form-control"
                                value={parseInt(value)}
                                onChange={(e) =>
                                  changeProductQuantity(
                                    e.target.value,
                                    item.slug
                                  )
                                }
                              >
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-2 col-12">
                            <p>{item.price * parseInt(value)} Azn</p>
                          </div>
                          <div className="col-lg-1 col-md-2 col-12">
                            <button
                              className="btn d-flex align-items-center justify-content-center p-2 fs-4 bg-danger rounded-circle text-white"
                              style={{ width: "32px", height: "32px" }}
                              onClick={() => removeFromBasket(item.slug)}
                            >
                              <IoClose />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>Sepet Boş!</p>
                )}
              </div>
            </div>

            <div className="col-lg-4 col-12">
              <div className="total-amount">
                <div className="right">
                  <ul>
                    <li>
                      Məhsullar<span>{totPrice.toFixed(2)} Azn</span>
                    </li>
                    <li>
                      Çatdırılma<span>0.00 Azn</span>
                    </li>
                    <li>
                      Qənaət<span>0.00 Azn</span>
                    </li>
                    <li className="last">
                      Toplam<span>{totPrice.toFixed(2)} Azn</span>
                    </li>
                  </ul>
                  <div className="button">
                    <button className="btn" onClick={() => handleConfirmCart()}>
                      Sepeti Tasdikle
                    </button>
                    {confirmCart === false && (
                      <div class="alert alert-danger" role="alert">
                        {confirmCartMessage}
                      </div>
                    )}
                    <a href="/az" className="btn btn-alt">
                      Alış verişə davam et
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
