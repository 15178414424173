import React, { useState, useEffect } from "react";

import "../App.css";
import ProductCard from "../Components/ProductCard";
import ComparisonTable from "../Components/ComparisonTable";
import { useStateContext } from "../App";

const ProductComparison = () => {
  const {
    productComparisons,
    removeProductFromComparison,
    clearProductComparison,
  } = useStateContext();
  const [products, setProducts] = useState([]);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [comparisonList, setComparisonList] = useState(productComparisons);

  useEffect(() => {
    setComparisonList(JSON.parse(localStorage.getItem("PRODUCT_COMPARISON")));
  }, []);

  useEffect(() => {
    homeProductDataFetch();
  }, [page]);

  const homeProductDataFetch = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/Product/get-all-products?LanguageCode=az&pageIndex=${page}`
      );

      const data = await response.json();
      if (data.data.items.length > 0) {
        setProducts((prevProducts) => {
          const newProducts = [...prevProducts, ...data.data.items];
          const uniqueProducts = Array.from(
            new Set(newProducts.map((product) => product.productId))
          ).map((productId) =>
            newProducts.find((product) => product.productId === productId)
          );
          sessionStorage.setItem("products", JSON.stringify(uniqueProducts));
          return uniqueProducts;
        });

        setHasMorePages(true);
      } else {
        setHasMorePages(false);
      }
    } catch (error) {
      setHasMorePages(false);
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="container py-5">
      <div className="row">
        <div className="col">
          <ComparisonTable data={comparisonList} />
        </div>
      </div>
    </div>
  );
};

export default ProductComparison;
