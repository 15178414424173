import React from "react";
import ReactModal from "react-modal";
import { useLocation, useNavigate } from "react-router-dom";

ReactModal.setAppElement("#root");

const MonthlyPayment = ({
  onRequestClose,
  children,
  style,
  // isOpen
}) => {
  const location = useLocation();
  const isOpen = location.pathname.includes("aylik-odenis");

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose} style={style}>
      {children}
    </ReactModal>
  );
};

export default MonthlyPayment;
