import { createContext, useContext, useEffect, useState } from "react";

const StateContext = createContext({
  user: null,
  token: null,
  likedProducts: [],
  lang: null,
  basket: [],
  setUser: () => {},
  setToken: () => {},
  setLikedProducts: () => {},
  setLang: () => {},
  setBasket: () => {},
});

export const ContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [token, _setToken] = useState(localStorage.getItem("access_token"));
  const [likedProducts, setLikedProducts] = useState([]);
  const [productComparisons, setProductComparisons] = useState([]);
  const [error, setError] = useState("");
  const [lang, setLang] = useState(localStorage.getItem("lang") || "az");
  const [basket, setBasket] = useState([]);
  const [finCode, setFinCode] = useState(null);

  useEffect(() => {
    const storedBasket = localStorage.getItem("basket");
    if (storedBasket) {
      setBasket(JSON.parse(storedBasket));
    }
  }, []);

  useEffect(() => {
    const storedComparisons = localStorage.getItem("PRODUCT_COMPARISON");
    if (storedComparisons) {
      setProductComparisons(JSON.parse(storedComparisons));
    }
  }, []);

  const setToken = (token) => {
    _setToken(token);

    if (token) {
      localStorage.setItem("access_token", token);
    } else {
      localStorage.removeItem("access_token");
    }
  };

  const updateLocalStorage = (comparisons) => {
    localStorage.setItem("PRODUCT_COMPARISON", JSON.stringify(comparisons));
  };

  const addProductToComparison = (productId) => {
    if (productComparisons.length >= 3) {
      setError("En fazla 3 ürün karşılaştırabilirsiniz.");
      return;
    }
    const productIndex = productComparisons.findIndex((p) => p === productId);
    if (productIndex === -1) {
      const updatedProducts = [...productComparisons, productId];
      setProductComparisons(updatedProducts);
      localStorage.setItem(
        "PRODUCT_COMPARISON",
        JSON.stringify(updatedProducts)
      );
    } else {
      const updatedProducts = [
        ...productComparisons.slice(0, productIndex),
        ...productComparisons.slice(productIndex + 1),
      ];
      setProductComparisons(updatedProducts);
      localStorage.setItem(
        "PRODUCT_COMPARISON",
        JSON.stringify(updatedProducts)
      );
    }
    setError("")
  };

  const removeProductFromComparison = (productId) => {
    setProductComparisons((prevComparisons) => {
      const updatedComparisons = prevComparisons.filter(
        (id) => id !== productId
      );
      updateLocalStorage(updatedComparisons);
      return updatedComparisons;
    });
  };

  const clearProductComparison = () => {
    setProductComparisons([]);
    localStorage.removeItem("PRODUCT_COMPARISON");
  };

  return (
    <StateContext.Provider
      value={{
        user,
        token,
        likedProducts,
        lang,
        basket,
        finCode,
        setFinCode,
        setUser,
        setToken,
        setLikedProducts,
        setLang,
        setBasket,
        productComparisons,
        removeProductFromComparison,
        addProductToComparison,
        clearProductComparison,
        error,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
